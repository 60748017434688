import styled from 'styled-components'
import { Radio } from '@nicollet/react'
import ndsTokens from 'nicollet-tokens'

export const StyledRadio = styled(Radio)`
  display: inline;
  padding-right: 40px;
  .radioBtn {
    font-size: ${ndsTokens.font.size.text.medium};
    @media (min-width: ${ndsTokens.breakpoints.sm}) {
      padding-right: 0;
      font-size: ${ndsTokens.font.size.body.large};
    }
  }
`
export const DeviceContainer = styled.div`
  padding-bottom: 30px;
`
export const RegisteredTrademark = styled.span`
  font-size: 15px;
  vertical-align: top;
`
