import {
  ButtonPrimary,
  ButtonSecondary,
  Col,
  Grid,
  Row,
  Spinner,
} from '@nicollet/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  HeadingContainer,
  StyledGridContainer,
  SubHeadingContainer,
} from '../../../common/styles/global-styles'
import {
  sanitizePhoneNumber,
  validateIsPhoneNumber,
} from '../../utils/forms/validation'
import {
  BodyMargin,
  DeviceContainer,
  PaddedLabel,
  PriceContainer,
  QuestionContainer,
  QuestionErrorContainer,
  QuoteErrorContainer,
  RefinedInput,
  RefinedInputPhoneNumber,
  StyledRadio,
} from './styles'
import { useNavigate, useLocation } from 'react-router'
import { Paths } from '../../enums/paths'
import { LabeledImage } from '../../components/labeledImage'
import { PopOver } from '../../components/popover'
import { Helmet } from 'react-helmet'
import { Question } from '../../interfaces/apiModels/response/questions/question'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchingQuestions,
  fetchQuestions,
  postQuote,
  postQuoteRefresh,
  saveDeviceConditionsResponseToSessionStorage,
  saveDeviceNumberToSessionStorage,
  updateQuoteRequestAnswer,
} from '../../store/actions/deviceConditionsActions'
import { DeviceConditionsState } from '../../store/interfaces/device_conditions_state'
import { RootState } from '../../store/reducers'
import { fetchProductFromSessionStorage } from '../../store/actions/productSelectedStorageActions'
import { AnswerText } from '../../enums/answer_text'
import { AnswerCode } from '../../enums/answer_code'
import { Product } from '../../interfaces/apiModels/response/products/product'
import { QuestionType } from '../../enums/question_type'
import { BaseURLState } from '../../store/interfaces/base_url_state'
import { fetchBaseURLFromSessionStorage } from '../../store/actions/baseURLStorageActions'
import { useAnalytics } from '@praxis/component-analytics'
import { RegisteredTrademark } from '../../components/home/styles'
import { ErrorPageNotFound } from '../../components/pageNotFound'

export const DeviceConditions = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  let location = useLocation()
  const productId: string | null = new URLSearchParams(location.search).get(
    'id',
  )
  let deviceConditions: DeviceConditionsState = useSelector(
    (state: RootState) => {
      return state.deviceConditions
    },
  )
  let selectedProduct: Product = useSelector((state: RootState) => {
    return state.productSelection.selectedProduct
  })
  const [productPrice, setProductPrice] = useState(
    selectedProduct.tradein_max_price,
  )
  const [imeiNumber, setImeiNumber] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  // form validation
  const [isImeiValid, setImeiValid] = useState(true)
  const [isOnSubmit, setOnSubmit] = useState(false)
  const [isImeiNumberSet, setImeiNumberSet] = useState(false)
  const [isRadioClicked, setRadioClicked] = useState(false)
  const [isPrevQuoteError, setPrevQuoteError] = useState(false)
  const [prevQuoteErrorMsg, setPrevQuoteErrorMsg] = useState('')
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(true)
  const [isFormValid, setFormValid] = useState(false)

  const [screenWidth, setScreenWidth]: [number, (checked: number) => void] =
    useState(window.screen.width)

  const imeiElementRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setScreenWidth(window.screen.width)
  }, [screenWidth])

  const isMobile = () => {
    return screenWidth < 480
  }

  const { trackClick } = useAnalytics()

  let {
    imeiQuestionNotFound,
    singleSelectQuestions,
    textQuestions,
    imeiQuestion,
    phoneQuestion,
    requiredQuestionsLength,
    loading,
    questionsError,
    questionsEmpty,
    questionsErrorResponse,
    quoteRequestAnswers,
    quoteResponse,
    quoteError,
    quoteErrorResponse,
    imeiValidated,
    deviceValidationResponse,
  } = deviceConditions

  useEffect(() => {
    dispatch(postQuoteRefresh())
  }, [])

  useEffect(() => {
    if (imeiValidated) {
      if (quoteError) {
        setImeiValid(false)
        imeiElementRef?.current?.focus()
      } else {
        setImeiValid(true)
      }
    }
  }, [quoteError, imeiValidated, isImeiValid])

  useEffect(() => {
    if (imeiQuestionNotFound) {
      if (!quoteError && isOnSubmit) {
        navigate(Paths.PersonalInformation)
      }
    } else {
      if (imeiValidated) {
        if (!quoteError && isImeiNumberSet && isImeiValid && isOnSubmit) {
          navigate(Paths.PersonalInformation)
        }
      }
    }
  }, [
    isImeiValid,
    imeiValidated,
    isOnSubmit,
    isImeiNumberSet,
    quoteError,
    imeiQuestionNotFound,
  ])

  useEffect(() => {
    if (!quoteError && isPrevQuoteError) {
      setImeiValid(false)
    }
  }, [quoteError, isPrevQuoteError])

  useEffect(() => {
    if (quoteError) {
      setPrevQuoteError(quoteError)
      setPrevQuoteErrorMsg(deviceValidationResponse?.message)
    }
    if (
      quoteError &&
      !isImeiValid &&
      isRadioClicked &&
      isPrevQuoteError &&
      prevQuoteErrorMsg !== ''
    ) {
      dispatch(
        updateQuoteRequestAnswer({
          question_id: imeiQuestion.question_id,
          answer_text: '',
          answer_code: imeiQuestion.question_type,
          question_type: imeiQuestion.question_type,
          question_text: imeiQuestion.text,
        }),
      )
    }
    if (
      quoteError &&
      !isImeiValid &&
      isRadioClicked &&
      isPrevQuoteError &&
      prevQuoteErrorMsg !== '' &&
      productId !== null
    ) {
      dispatch(postQuote(baseURL, productId, false))
    }
  }, [
    quoteError,
    isImeiValid,
    isRadioClicked,
    isPrevQuoteError,
    prevQuoteErrorMsg,
  ])

  const handleRadioOnChange = (
    answerText: string,
    answerCode: string,
    question: Question,
  ) => {
    setOnSubmit(false)
    dispatch(
      updateQuoteRequestAnswer({
        question_id: question.question_id,
        answer_text: answerText,
        answer_code: answerCode,
        question_type: question.question_type,
        question_text: question.text,
      }),
    )
    setRadioClicked(true)

    if (!quoteError) {
      setPrevQuoteError(false)
      if (productId !== null) {
        dispatch(postQuote(baseURL, productId, false))
      }
    } else {
      setPrevQuoteError(quoteError)
      setPrevQuoteErrorMsg(deviceValidationResponse?.message)
    }
  }

  let baseURLDetails: BaseURLState = useSelector((state: RootState) => {
    return state.baseURL
  })
  let { baseURL, baseURLExist } = baseURLDetails

  useEffect(() => {
    dispatch(fetchBaseURLFromSessionStorage())
  }, [])

  useEffect(() => {
    if (baseURLExist) {
      dispatch(fetchingQuestions())
      dispatch(fetchProductFromSessionStorage())
      if (productId) dispatch(fetchQuestions(baseURL, productId))
    }
  }, [baseURLExist])

  useEffect(() => {
    setProductPrice(selectedProduct.tradein_max_price)
  }, [selectedProduct])

  useEffect(() => {
    setProductPrice(quoteResponse.amount)
  }, [quoteResponse])

  useEffect(() => {
    let isAllQuestionsAnswered: boolean =
      quoteRequestAnswers.length === requiredQuestionsLength
    let isResponseValid: boolean = imeiQuestionNotFound
      ? isAllQuestionsAnswered && isPhoneNumberValid
      : isAllQuestionsAnswered && isImeiNumberSet && isPhoneNumberValid
    setFormValid(questionsError || quoteError ? false : isResponseValid)
  }, [
    quoteRequestAnswers.length,
    isImeiNumberSet,
    isPhoneNumberValid,
    imeiNumber,
    phoneNumber,
    requiredQuestionsLength,
    imeiQuestionNotFound,
    questionsError,
    quoteError,
  ])

  const getAnswerValue = (questionId: string) => {
    let answerText = AnswerText.Yes.toString()
    quoteRequestAnswers.forEach((answer) => {
      if (answer.question_id === questionId) {
        answerText = answer.answer_text
      }
    })
    return answerText
  }
  const handleImeiOnChange = useCallback((value: any, question: Question) => {
    dispatch(postQuoteRefresh())
    setOnSubmit(false)
    if (value !== '') {
      setImeiNumberSet(true)
      setImeiValid(true)
    } else {
      setImeiNumberSet(false)
    }
    dispatch(
      updateQuoteRequestAnswer({
        question_id: question.question_id,
        answer_text: value,
        answer_code: question.question_type,
        question_type: question.question_type,
        question_text: question.text,
      }),
    )
  }, [])

  const handleChangeOnBlur = useCallback((value: any, question: Question) => {
    if (question.question_id.includes('IMEI')) {
      setImeiNumber(value)
    } else if (question.question_id.includes('PHONE_NUM')) {
      setPhoneNumber(sanitizePhoneNumber(value))
    }
    dispatch(
      updateQuoteRequestAnswer({
        question_id: question.question_id,
        answer_text: value,
        answer_code: question.question_type,
        question_type: question.question_type,
        question_text: question.text,
      }),
    )
  }, [])

  const handlePhoneNumberOnChange = useCallback((value) => {
    setPhoneNumberValid(validateIsPhoneNumber(value))
  }, [])

  const onSubmit = async () => {
    trackClick('Submit_Device_Condition_Check', selectedProduct.product_title)
    setRadioClicked(false)
    if (productId !== null) await dispatch(postQuote(baseURL, productId, true))
    await dispatch(saveDeviceNumberToSessionStorage(imeiNumber))
    await dispatch(
      saveDeviceConditionsResponseToSessionStorage(
        quoteRequestAnswers.filter(
          (answer) => answer.question_type === QuestionType.Single_Select,
        ),
      ),
    )
    setOnSubmit(true)
  }

  const preventAction = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <div>
      <Helmet defaultTitle="Device Conditions" />
      {baseURLExist ? (
        <form>
          <Grid>
            <StyledGridContainer>
              <HeadingContainer role="heading" aria-level="2">
                Check Device Condition
              </HeadingContainer>
              <SubHeadingContainer>
                Please answer the following questions about the device you want
                to trade in so we can estimate its trade-in value. Please note-
                the final trade-in value of your device will be determined upon
                its receipt and inspection at the Assurant
                <RegisteredTrademark>&reg;</RegisteredTrademark> warehouse.
              </SubHeadingContainer>

              <BodyMargin>
                <Row xsGutter="tight">
                  {isMobile() && (
                    <Col lg={3} xs={12}>
                      <DeviceContainer>
                        <Row center="xs">
                          <PaddedLabel>You are trading in:</PaddedLabel>
                        </Row>
                        <Row center="xs">
                          <LabeledImage
                            altText={selectedProduct.product_title}
                            imageUrl={selectedProduct.image_url}
                            imageWidth={'150'}
                            label={selectedProduct.product_title}
                            page={'DeviceConditionsPage'}
                          />
                        </Row>
                        <Row center="xs">
                          <div>The estimated trade-in value:</div>
                        </Row>
                        <Row center="xs">
                          <PriceContainer>
                            ${productPrice.toFixed(2)}
                          </PriceContainer>
                        </Row>
                      </DeviceContainer>
                    </Col>
                  )}
                  <Col lg={9} xs={12}>
                    {loading && <Spinner size="large" />}
                    {questionsEmpty && (
                      <QuestionErrorContainer>
                        Something went wrong, please try again later
                      </QuestionErrorContainer>
                    )}

                    {questionsError &&
                      questionsErrorResponse?.code ===
                        'TRADEIN_PRODUCT_NOT_SUPPORTED' && (
                        <QuestionErrorContainer>
                          {questionsErrorResponse?.message}
                        </QuestionErrorContainer>
                      )}

                    {questionsError &&
                      questionsErrorResponse?.code !==
                        'TRADEIN_PRODUCT_NOT_SUPPORTED' && (
                        <QuestionErrorContainer>
                          Something went wrong, please try again later
                        </QuestionErrorContainer>
                      )}

                    {quoteError &&
                      deviceValidationResponse?.code !==
                        'TRADEIN_DEVICE_VALIDATION_FAILED' &&
                      quoteErrorResponse?.code !==
                        'TRADEIN_PRODUCT_NOT_SUPPORTED' && (
                        <QuoteErrorContainer>
                          Something went wrong, please try again later
                        </QuoteErrorContainer>
                      )}

                    {quoteError &&
                      quoteErrorResponse?.code ===
                        'TRADEIN_PRODUCT_NOT_SUPPORTED' && (
                        <QuoteErrorContainer>
                          {quoteErrorResponse?.message}
                        </QuoteErrorContainer>
                      )}

                    {!loading && !questionsError && (
                      <div>
                        {singleSelectQuestions.length > 0 &&
                          singleSelectQuestions.map(
                            (question: Question, index: number) => (
                              <Row key={index}>
                                <Col lg={7} xs={12}>
                                  <QuestionContainer>
                                    <label>{question.text}</label>
                                    <PopOver
                                      id={question.question_id}
                                      popText={question.help_text}
                                    />
                                  </QuestionContainer>
                                </Col>
                                <Col lg={1} md={2} xs={3}>
                                  <StyledRadio
                                    checked={
                                      getAnswerValue(question.question_id) ===
                                      AnswerText.Yes.toString()
                                    }
                                    name={'selectYes' + question.question_id}
                                    onChange={() =>
                                      handleRadioOnChange(
                                        AnswerText.Yes,
                                        AnswerCode.Y,
                                        question,
                                      )
                                    }
                                    value={AnswerText.Yes}
                                  >
                                    {' '}
                                    Yes
                                  </StyledRadio>
                                </Col>

                                <Col lg={1} md={2} xs={3}>
                                  <StyledRadio
                                    checked={
                                      getAnswerValue(question.question_id) ===
                                      AnswerText.No.toString()
                                    }
                                    name={'selectNo' + question.question_id}
                                    onChange={() =>
                                      handleRadioOnChange(
                                        AnswerText.No,
                                        AnswerCode.N,
                                        question,
                                      )
                                    }
                                    value={AnswerText.No}
                                  >
                                    {' '}
                                    No
                                  </StyledRadio>
                                </Col>
                              </Row>
                            ),
                          )}
                        {imeiQuestion.question_id !== '' && (
                          <Row>
                            <Col lg={7} xs={12}>
                              <QuestionContainer>
                                <label>{imeiQuestion.text}</label>
                                <PopOver
                                  id={imeiQuestion.question_id}
                                  popText={imeiQuestion.help_text}
                                />
                              </QuestionContainer>
                            </Col>
                            <Col lg={5} md={5} xs={12}>
                              <RefinedInput
                                className="searchInput"
                                childRef={imeiElementRef}
                                errorText={
                                  deviceValidationResponse?.code ===
                                  'TRADEIN_DEVICE_VALIDATION_FAILED'
                                    ? deviceValidationResponse?.message
                                    : ''
                                } // todo: need to handle "This field is required"
                                id={imeiQuestion.question_id}
                                isValid={isImeiValid}
                                onBlur={(value) =>
                                  handleChangeOnBlur(value, imeiQuestion)
                                }
                                onChange={(value) =>
                                  handleImeiOnChange(value, imeiQuestion)
                                }
                                onEnterKeyPress={preventAction}
                                required={imeiQuestion.required}
                              />
                            </Col>
                          </Row>
                        )}
                        {phoneQuestion.question_id !== '' && (
                          <Row>
                            <Col lg={7} xs={12}>
                              <QuestionContainer>
                                <label>{phoneQuestion.text}</label>
                                <PopOver
                                  id={phoneQuestion.question_id}
                                  popText={phoneQuestion.help_text}
                                />
                              </QuestionContainer>
                            </Col>
                            <Col lg={5} md={5} xs={12}>
                              <RefinedInputPhoneNumber
                                className="searchInput"
                                errorText="Phone Number is not valid"
                                id={phoneQuestion.question_id}
                                isValid={isPhoneNumberValid}
                                label="Enter guest's mobile number"
                                onBlur={(value) =>
                                  handleChangeOnBlur(value, phoneQuestion)
                                }
                                onChange={handlePhoneNumberOnChange}
                                required={phoneQuestion.required}
                              />
                            </Col>
                          </Row>
                        )}
                        {textQuestions.length > 0 &&
                          textQuestions.map(
                            (question: Question, index: number) => (
                              <Row key={index}>
                                <Col lg={7} xs={12}>
                                  <QuestionContainer>
                                    <label>{question.text}</label>
                                    <PopOver
                                      id={question.question_id}
                                      popText={question.help_text}
                                    />
                                  </QuestionContainer>
                                </Col>
                                <Col lg={5} md={5} xs={12}>
                                  <RefinedInput
                                    className="searchInput"
                                    errorText="answer is required"
                                    label={question.text}
                                    onBlur={(value) =>
                                      handleChangeOnBlur(value, question)
                                    }
                                    required={question.required}
                                  />
                                </Col>
                              </Row>
                            ),
                          )}
                      </div>
                    )}
                  </Col>
                  {!isMobile() && (
                    <Col lg={3} xs={12}>
                      <DeviceContainer>
                        <Row center="xs">
                          <PaddedLabel>You are trading in:</PaddedLabel>
                        </Row>
                        <Row center="xs">
                          <LabeledImage
                            altText={selectedProduct.product_title}
                            imageUrl={selectedProduct.image_url}
                            imageWidth={'150'}
                            label={selectedProduct.product_title}
                            page={'DeviceConditionsPage'}
                          />
                        </Row>
                        <Row center="xs">
                          <div>The estimated trade-in value:</div>
                        </Row>
                        <Row center="xs">
                          <PriceContainer>
                            ${productPrice.toFixed(2)}
                          </PriceContainer>
                        </Row>
                      </DeviceContainer>
                    </Col>
                  )}
                </Row>
              </BodyMargin>

              <Row style={{ paddingTop: '30px' }} xsGutter="tight">
                <Col xs={6}>
                  <ButtonSecondary isFullWidth href={Paths.Home}>
                    Cancel
                  </ButtonSecondary>
                </Col>
                <Col xs={6}>
                  <ButtonPrimary
                    disabled={!isFormValid}
                    isFullWidth
                    onClick={onSubmit}
                  >
                    Continue
                  </ButtonPrimary>
                </Col>
              </Row>
            </StyledGridContainer>
          </Grid>
        </form>
      ) : (
        <ErrorPageNotFound />
      )}
    </div>
  )
}
