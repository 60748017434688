import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { ModelState } from '../interfaces/model_state'

const initialState: ModelState = {
  models: [],
  count: 0,
  loading: false,
  modelError: false,
  popularModelErrorResponse: {
    code: '',
    message: '',
  },
}
export const popularProductReducer = (
  state: ModelState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionTypes.FETCHING_POPULAR_MODELS:
      return { ...state, loading: true, models: [] }
    case ActionTypes.FETCH_POPULAR_MODELS:
      return {
        ...state,
        count: payload.count,
        models: payload.products,
        loading: false,
        modelError: false,
      }
    case ActionTypes.FETCH_POPULAR_MODELS_ERROR:
      return {
        ...state,
        loading: false,
        modelError: true,
        popularModelErrorResponse: payload,
      }
    default:
      return state
  }
}
