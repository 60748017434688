import { Col, Grid, Row } from '@nicollet/react'
import { IconToolsMapAvailable } from 'nicollet-icons-react'
import { AddressContainer } from './styles'
import { AddressSuggestionProperties } from './suggestionProps'
import React from 'react'

export const AddressSuggestion: React.FC<AddressSuggestionProperties> = ({
  index,
  addressSuggestion,
  addressSelectionCallback,
}) => {
  const address = addressSuggestion.address

  const onKeyPressed = (event: React.KeyboardEvent) => {
    if (
      (event instanceof KeyboardEvent && event.key === 'Enter') ||
      event.key === ' '
    ) {
      addressSelectionCallback(address, index)
    }
  }
  return (
    <Grid>
      <AddressContainer
        onClick={() => addressSelectionCallback(address, index)}
        onKeyPress={onKeyPressed}
        tabIndex={0}
      >
        <Row>
          <Col xs={3}>
            <IconToolsMapAvailable size={48} />
          </Col>
          <Col xs={9}>
            <Row>{address.address_line1}</Row>
            {address.address_line2 && <Row>{address.address_line2}</Row>}
            <Row>
              <Col>{address.city},&nbsp;</Col>
              <Col> {address.state}&nbsp;</Col>
              <Col>{address.zip_code}</Col>
            </Row>
          </Col>
        </Row>
      </AddressContainer>
    </Grid>
  )
}
