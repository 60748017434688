import { BaseModalFloat, ButtonTertiary } from '@nicollet/react'
import { IconAlertsInfoCircle } from 'nicollet-icons-react'
import { useState } from 'react'
import { PopverProperties } from './popover-properties'
import { RefinedSpan } from './styles'

interface PopverStatus {
  [key: string]: boolean
}

export const PopOver: React.FC<any> = ({ id, popText }: PopverProperties) => {
  const [popoverStatus, setPopoverStatus] = useState<PopverStatus>({})

  const openButtonPopover = (id: string) => {
    const tempPopOver = { ...popoverStatus, [id]: true }
    setPopoverStatus(tempPopOver)
  }
  const closeButtonPopover = (id: string) => {
    const tempPopOver = { ...popoverStatus, [id]: false }
    setPopoverStatus(tempPopOver)
  }

  return (
    <>
      <RefinedSpan>
        <ButtonTertiary
          onClick={() => openButtonPopover(id)}
          aria-label={'Info for ' + id}
        >
          <IconAlertsInfoCircle height="16px" width="15px" />
        </ButtonTertiary>
        <BaseModalFloat
          headingText={id}
          iconButtonCloseOnClick={() => closeButtonPopover(id)}
          isOpen={popoverStatus[id]}
          onRequestClose={() => closeButtonPopover(id)}
        >
          {popText}
        </BaseModalFloat>
      </RefinedSpan>
    </>
  )
}
