import { combineReducers } from 'redux'
import { categoryReducer } from './categoryReducer'
import { customerReducer } from './customerReducer'
import { deviceConditionsReducer } from './deviceConditionsReducer'
import { deviceReducer } from './deviceReducer'
import { manufacturerReducer } from './manufacturerReducer'
import { orderReducer } from './orderReducer'
import { popularProductReducer } from './popularModelReducer'
import { modelReducer } from './modelReducer'
import { productSelectedStorageReducer } from './productSelectedStorageReducer'
import { storageReducer } from './storageReducer'
import { capacityReducer } from './capacityReducer'
import { baseURLStorageReducer } from './baseURLStorageReducer'

export const reducers = combineReducers({
  models: modelReducer,
  popularModels: popularProductReducer,
  categories: categoryReducer,
  manufacturers: manufacturerReducer,
  devices: deviceReducer,
  capacities: capacityReducer,
  deviceConditions: deviceConditionsReducer,
  productSelection: productSelectedStorageReducer,
  customer: customerReducer,
  order: orderReducer,
  baseURL: baseURLStorageReducer,
  storage: storageReducer,
})

export type RootState = ReturnType<typeof reducers>
