import { TradeInProvider } from '../../enums/tradein_provider'
import { Filters } from '../../interfaces/filters'
import { ActionTypes } from '../constants/action-types'
import { Params } from '../../interfaces/params'
import { Action } from '../interfaces/action'
import { axiosInstance } from '../../apis/axiosInstance'
import { handleException } from '../../utils/error-handler'

export const fetchingPopularModels = () => {
  return {
    type: ActionTypes.FETCHING_POPULAR_MODELS,
  }
}

export const fetchPopularModels = (
  baseUrl: string,
  filters: Filters,
  limit: number,
) => {
  return async (dispatch: any) => {
    try {
      const filterEncoded = encodeURIComponent(JSON.stringify(filters))
      const params: Params = {
        tradein_provider: TradeInProvider.Assurant,
        per_page: limit,
      }
      const response = await axiosInstance.get(
        `${baseUrl}/products?filters=${filterEncoded}`,
        { params: params },
      )
      let action: Action = {
        type: ActionTypes.FETCH_POPULAR_MODELS,
        payload: response.data,
      }
      dispatch(action)
    } catch (error: any) {
      handleException(error.response)
      let errorAction: Action = {
        type: ActionTypes.FETCH_POPULAR_MODELS_ERROR,
        payload: error.response?.data,
      }
      dispatch(errorAction)
    }
  }
}
