import { TradeInProvider } from '../../enums/tradein_provider'
import { ActionTypes } from '../constants/action-types'
import { Params } from '../../interfaces/params'
import { Action } from '../interfaces/action'
import { FilterType } from '../../interfaces/filter_type'
import { axiosInstance } from '../../apis/axiosInstance'
import { handleException } from '../../utils/error-handler'

export const fetchingCategories = () => {
  return {
    type: ActionTypes.FETCHING_CATEGORIES,
  }
}

// Fetch categories does not need any filters or limit, it only needs Trade-In provider, ex, Assurant.
// Example url to Trade-in service to fetch the categories:
//
// https://tradeinservicesv1.dev.target.com/tradein_services/v1/product_filters?tradein_provider=HYLA
export const fetchCategories = (baseURL: string) => {
  return async (dispatch: any) => {
    try {
      const params: Params = {
        tradein_provider: TradeInProvider.Assurant,
      }
      const response = await axiosInstance.get(`${baseURL}/product_filters`, {
        params: params,
      })

      let action: Action = {
        type: ActionTypes.FETCH_CATEGORIES,
        payload: response.data.available_filters.filter(
          (available_filter) =>
            available_filter.filter_name === FilterType.CATEGORY,
        )[0].data,
      }
      dispatch(action)
    } catch (error: any) {
      handleException(error.response)
      let errorAction: Action = {
        type: ActionTypes.FETCH_CATEGORIES_ERROR,
      }
      dispatch(errorAction)
    }
  }
}
