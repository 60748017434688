import { useEffect, useState } from 'react'
import {
  HeadingContainer,
  StyledGridContainer,
  SubHeadingContainer,
} from '../../../common/styles/global-styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { Paths } from '../../enums/paths'
import { Col, Grid, Row } from '@nicollet/react'
import { DeviceContainer, RegisteredTrademark, StyledRadio } from './styles'
import { BrowseCategoryLayout } from '../browseCategoryLayout'
import { Helmet } from 'react-helmet'
import { functionToRetrieveFromConfigFile } from '../../../common/apis/storeApi'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchBaseURLFromSessionStorage,
  saveBaseURLToSessionStorage,
} from '../../store/actions/baseURLStorageActions'
import { BaseURLState } from '../../store/interfaces/base_url_state'
import { RootState } from '../../store/reducers'
import { useAnalytics } from '@praxis/component-analytics'
import {
  BROWSE_BY_CATEGORY_BUTTON_LABEL,
  SEARCH_BY_DEVICE_BUTTON_LABEL,
} from '../../constants/common-constants'

export const Home = (radioSelectValue: string) => {
  let navigate = useNavigate()
  let location = useLocation()
  const dispatch = useDispatch()

  const { trackClick } = useAnalytics()

  const [radioChecked, setRadioChecked]: [
    string,
    (radioChecked: string) => void,
  ] = useState(radioSelectValue === 'search' ? radioSelectValue : 'browse')

  let baseURLDetails: BaseURLState = useSelector((state: RootState) => {
    return state.baseURL
  })
  let { baseURLExist } = baseURLDetails

  useEffect(() => {
    functionToRetrieveFromConfigFile().then((x) => {
      let baseURL = x.trade_in_services_v1.live.baseUrl
      dispatch(saveBaseURLToSessionStorage(baseURL))
    })

    dispatch(fetchBaseURLFromSessionStorage())
  }, [location?.pathname])

  useEffect(() => {
    if (baseURLExist) {
      if (location?.pathname.indexOf('/search') === 0) {
        setRadioChecked('search')
      } else {
        setRadioChecked('browse')
      }
    }
  }, [location?.pathname, baseURLExist])

  const onClick = (selection: string) => {
    setRadioChecked(selection)
    if (selection === 'browse') {
      trackClick('Browse_Type_Selection', 'Browse Devices')
      navigate(Paths.Categories)
    } else if (selection === 'search') {
      trackClick('Browse_Type_Selection', 'Text Search')
      navigate(Paths.Search)
    }
  }

  return (
    <>
      <Helmet defaultTitle="Trade-in" />
      <StyledGridContainer>
        <Grid>
          <Row>
            <DeviceContainer>
              <HeadingContainer role="heading" aria-level="2">
                Welcome to the Target Trade-in program - powered by Assurant
                <RegisteredTrademark>&reg;</RegisteredTrademark>
              </HeadingContainer>
              <SubHeadingContainer>
                Trade in your iPhones, iPads, Smartphones, Video game Consoles,
                Smart Watches, and Smart Speakers for an electronic Target gift
                card. Declutter, recycle and save!
                <br /> Get started below
              </SubHeadingContainer>
            </DeviceContainer>
          </Row>
          <Row bottom="xs">
            <Col lg={3} xs={6}>
              <StyledRadio
                checked={radioChecked === 'browse'}
                name="browse"
                autoFocus={radioChecked === 'browse'}
                onChange={() => onClick('browse')}
              >
                <span className="radioBtn">
                  {BROWSE_BY_CATEGORY_BUTTON_LABEL}
                </span>
              </StyledRadio>
            </Col>
            <Col lg={3} xs={6}>
              <StyledRadio
                checked={radioChecked === 'search'}
                name="search"
                autoFocus={radioChecked === 'search'}
                onChange={() => onClick('search')}
              >
                <span className="radioBtn">
                  {' '}
                  {SEARCH_BY_DEVICE_BUTTON_LABEL}{' '}
                </span>
              </StyledRadio>
            </Col>
          </Row>
          {radioChecked === 'browse' && <BrowseCategoryLayout />}
        </Grid>
      </StyledGridContainer>
    </>
  )
}
