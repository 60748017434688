import { useNavigate, useLocation } from 'react-router-dom'
import { BrowseTypeGridContainer } from '../../../common/styles/global-styles'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/reducers'
import { Filters } from '../../interfaces/filters'
import { FilterType } from '../../interfaces/filter_type'
import { MANUFACTURER_DISPLAY_LIMIT } from '../../constants'
import { useEffect, useState } from 'react'
import {
  fetchingDevices,
  fetchDevices,
} from '../../store/actions/deviceActions'
import { DeviceState } from '../../store/interfaces/device_state'
import {
  fetchCapacitites,
  fetchingCapacities,
} from '../../store/actions/capacityActions'
import { CapacityState } from '../../store/interfaces/capacity_state'
import { SearchResults } from '../searchResults'
import { BaseURLState } from '../../store/interfaces/base_url_state'
import { fetchBaseURLFromSessionStorage } from '../../store/actions/baseURLStorageActions'
import { Home } from '../home'
import { GenericErrorContainer } from '../styles'
import { GENERIC_ERROR_MESSAGE } from '../../constants/common-constants'

export const Devices = () => {
  let navigate = useNavigate()
  let location = useLocation()
  const dispatch = useDispatch()
  let category = ''
  let manufacturer = ''
  const [deviceSelected, setDeviceSelected] = useState('')

  let deviceModelsState: DeviceState = useSelector((state: RootState) => {
    return state.devices
  })

  let modelVariantsState: CapacityState = useSelector((state: RootState) => {
    return state.capacities
  })
  let { capacitiesEmpty, loadNext, capacityError } = modelVariantsState

  let baseURLDetails: BaseURLState = useSelector((state: RootState) => {
    return state.baseURL
  })
  let { baseURL, baseURLExist } = baseURLDetails

  // Example: /browse/category/iPhone/manufacturer/Apple/devicemodel
  // The pathnames will have total 7 elements, including the first element being empty.
  let pathnames = location.pathname.split('/', 7)
  category = pathnames[3]
  manufacturer = pathnames[5]

  useEffect(() => {
    dispatch(fetchBaseURLFromSessionStorage())
  }, [])

  useEffect(() => {
    if (baseURLExist) {
      dispatch(fetchingDevices())
      dispatch(fetchingCapacities())
      loadNext = false

      let filters: Filters = {
        filters: [
          { name: FilterType.CATEGORY, value: category },
          { name: FilterType.MANUFACTURER, value: manufacturer },
        ],
      }
      dispatch(fetchDevices(baseURL, filters, MANUFACTURER_DISPLAY_LIMIT)) // FIXME define new constant once we decide the approach
    }
  }, [baseURLExist])

  let { devices, loading, deviceError, devicesEmpty } = deviceModelsState

  const onChange = (device: string) => {
    setDeviceSelected(device)
    let filters: Filters = {
      filters: [
        { name: FilterType.CATEGORY, value: category },
        { name: FilterType.MANUFACTURER, value: manufacturer },
        { name: FilterType.PRODUCT_FAMILY, value: device },
      ],
    }
    dispatch(fetchingCapacities())
    dispatch(fetchCapacitites(baseURL, filters, MANUFACTURER_DISPLAY_LIMIT))
  }

  useEffect(() => {
    if (loadNext) {
      if (capacitiesEmpty) {
        navigate(`${location.pathname}/${deviceSelected}/capacity/0/model`)
      } else {
        navigate(`${location.pathname}/${deviceSelected}/capacity`)
      }
    }
  }, [capacitiesEmpty, loadNext, deviceSelected])

  return (
    <>
      {Home('browse')}
      <BrowseTypeGridContainer>
        {(deviceError || capacityError) && (
          <GenericErrorContainer
            className="errors"
            role="alert"
            aria-relevant="all"
            tabIndex={0}
          >
            {GENERIC_ERROR_MESSAGE}
          </GenericErrorContainer>
        )}
        {!deviceError && !devicesEmpty ? (
          <SearchResults
            filter_results={devices}
            callBack={onChange}
            loading={loading}
            searchLabel={'Browse_Devices'}
          />
        ) : (
          ''
        )}
      </BrowseTypeGridContainer>
    </>
  )
}
