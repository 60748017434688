import { Provider } from 'react-redux'
import store from './trade-in/store/configureStore'
import Routing from './trade-in/routing'
import { Header } from './trade-in/components/header'
import { Footer, Spinner } from '@nicollet/react'
import { EnvProvider } from '@praxis/component-runtime-env'
import {
  Container,
  ContentViewport,
  FooterContainer,
  GlobalStyles,
  SpinnerWrapper,
} from './common/styles/global-styles'
import { InitializeData } from './trade-in/utils/initializeData'
import { useEffect, useState } from 'react'
import appConfig from './apiConfig'

const App = () => {
  const [contentLoaded, setContentLoaded] = useState(false)

  useEffect(() => {
    appConfig().then((config) => {
      InitializeData(config).then(() => setContentLoaded(true))
    })
  }, [])

  const tradeinApplication = (
    <Provider store={store}>
      <GlobalStyles />
      <Container>
        <Header />
        <ContentViewport>
          <Routing />
        </ContentViewport>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </Container>
    </Provider>
  )

  //shouldn't show unless we add more awaits to initializeData()
  const spinner = (
    <div>
      <GlobalStyles />
      <Container>
        <Header />
        <SpinnerWrapper>
          <Spinner size="large" layout="selfCentering" />
        </SpinnerWrapper>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </Container>
    </div>
  )

  return (
    <EnvProvider
      configPath={
        process.env.NODE_ENV === 'development'
          ? '/config.json'
          : '/app-environment'
      }
      loadingIndicator={true}
    >
      {() => {
        if (contentLoaded) {
          return tradeinApplication
        } else {
          return spinner
        }
      }}
    </EnvProvider>
  )
}
export default App
