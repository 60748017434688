import { useState } from 'react'
import { Grid, Pagination, Row } from '@nicollet/react'
import { PageOptions } from './page-options'
import { DisplayProperties } from './display-properties'
import { BrowseTypeGridContainer } from '../../../common/styles/global-styles'
import { PaginationContainer } from './styles'
import { SearchResults } from '../searchResults'

export const DisplayDeviceModel: React.FC<any> = ({
  onChangeCallBack,
  onProductSelect,
  perPage,
  deviceModels,
  modelsCount,
}: DisplayProperties) => {
  const [page, setPage]: [number, (page: number) => void] = useState(1)
  const maxPages: number = Math.ceil(modelsCount / perPage)

  const getPageData = (page: number) => {
    onChangeCallBack(page)
  }

  const handleNext = () => {
    getPageData(page + 1)
    setPage(page + 1)
  }

  const handlePrev = () => {
    getPageData(page - 1)
    setPage(page - 1)
  }

  const handleOnPageSelect = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.MouseEvent<HTMLDivElement>,
    option: PageOptions,
  ) => {
    event.stopPropagation()
    const { value } = option
    getPageData(Number(value))
    setPage(Number(value))
  }

  const getPageList = () =>
    Array.from({ length: maxPages }).map((_, index) => ({
      label: `${index + 1}`,
      value: `${index + 1}`,
      selected: page === index + 1,
    }))

  return (
    <>
      <Grid>
        <BrowseTypeGridContainer>
          <SearchResults
            product_results={deviceModels}
            callBack={onProductSelect}
            loading={false}
            searchLabel={'Device_Model'}
          />
          {modelsCount > perPage && (
            <Row center="lg">
              <PaginationContainer>
                <Pagination
                  currentPage={page}
                  nextDisabled={page === maxPages}
                  nextOnClick={handleNext}
                  onPageSelect={handleOnPageSelect}
                  pages={getPageList()}
                  prevDisabled={page === 1}
                  prevOnClick={handlePrev}
                />
              </PaginationContainer>
            </Row>
          )}
        </BrowseTypeGridContainer>
      </Grid>
    </>
  )
}
