import { ErrorContainer } from './styles'
import { PageNotFound } from '@nicollet/react'
import { useLocation } from 'react-router-dom'
import { useAnalytics } from '@praxis/component-analytics'
import { useEffect } from 'react'

export const ErrorPageNotFound = () => {
  let location = useLocation()
  const analyticProps = {
    event: {
      name: 'Page Not Found',
      type: 'Error',
    },
    user: { customDimension1: location.pathname },
  }
  const { trackView, trackEvent } = useAnalytics()
  useEffect(() => {
    trackView('Page Not Found')
    trackEvent(analyticProps)
  }, [])
  return (
    <ErrorContainer>
      <PageNotFound />
    </ErrorContainer>
  )
}
