import { Col } from '@nicollet/react'
import {
  BodyRow,
  Bold,
  DetailRow,
  HeaderRow,
  InfoGrid,
  RowLabel,
} from './styles'
import { InfoPaneParams } from './info-pane-params'

export const InfoPane: React.FC<any> = ({
  deviceName,
  imei,
  quoteDetails,
}: InfoPaneParams) => {
  return (
    <InfoGrid>
      <HeaderRow>
        <RowLabel role="heading" aria-level="3">
          DEVICE INFORMATION
        </RowLabel>
      </HeaderRow>
      <BodyRow>
        <Col xs={4}>
          <RowLabel role="heading" aria-level="3">
            Device Name:
          </RowLabel>
        </Col>
        <Col xs={8}>{deviceName}</Col>
      </BodyRow>
      {imei !== '' && (
        <BodyRow>
          <Col xs={4}>
            <RowLabel role="heading" aria-level="3">
              IMEI/Serial Number:
            </RowLabel>
          </Col>
          <Col xs={8}>{imei}</Col>
        </BodyRow>
      )}
      <DetailRow>
        <RowLabel role="heading" aria-level="3">
          Quote Details:
        </RowLabel>
        {quoteDetails.map((answerDetail, index) => (
          <p key={index}>
            {answerDetail.question_text} :{' '}
            <Bold>{answerDetail.answer_text}</Bold>
          </p>
        ))}
      </DetailRow>
    </InfoGrid>
  )
}
