import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { ModelState } from '../interfaces/model_state'

const initialState: ModelState = {
  models: [],
  modelsEmpty: false,
  count: 0,
  loading: false,
  modelError: false,
  loadNext: false,
}
export const modelReducer = (
  state: ModelState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionTypes.FETCHING_MODELS:
      return {
        ...state,
        loading: true,
        models: [],
        modelError: false,
        modelsEmpty: false,
      }
    case ActionTypes.FETCH_MODELS:
      return {
        ...state,
        count: payload.count,
        models: payload.products,
        loading: false,
        modelsEmpty: payload.count === 0,
        loadNext: payload.count !== 0,
        modelError: payload.count === 0,
      }
    case ActionTypes.FETCH_MODELS_ERROR:
      return { ...state, loading: false, modelError: true, loadNext: false }
    case ActionTypes.SAVE_SEARCH_RESULTS_TO_SESSION:
      return {
        ...state,
        count: payload.count,
        models: payload.products,
        loading: false,
        modelError: false,
        loadNext: false,
      }
    case ActionTypes.SAVE_SEARCH_RESULTS_TO_SESSION_ERROR:
      return { ...state, loading: false, modelError: true, loadNext: false }
    case ActionTypes.FETCH_SEARCH_RESULTS_FROM_SESSION:
      return {
        ...state,
        count: payload.count,
        models: payload.products,
        modelError: false,
      }
    case ActionTypes.FETCH_SEARCH_RESULTS_FROM_SESSION_ERROR:
      return { ...state, loading: false, modelError: true, loadNext: false }
    default:
      return state
  }
}
