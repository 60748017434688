import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { CapacityState } from '../interfaces/capacity_state'

const initialState: CapacityState = {
  capacities: [],
  capacitiesEmpty: false,
  count: 0,
  loading: false,
  capacityError: false,
  loadNext: false,
}
export const capacityReducer = (
  state: CapacityState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionTypes.FETCHING_CAPACITIES:
      return {
        ...state,
        loading: true,
        capacitiesEmpty: [],
        loadNext: false,
        capacityError: false,
      }
    case ActionTypes.FETCH_CAPACITIES:
      return {
        ...state,
        count: payload.length,
        capacities: payload,
        loading: false,
        capacitiesEmpty: payload.length === 0,
        loadNext: true,
      }
    case ActionTypes.FETCH_CAPACITIES_ERROR:
      return { ...state, loading: false, capacityError: true }
    case ActionTypes.SAVE_CAPACITIES_TO_SESSION:
      return { ...state, capacities: payload, capacityError: false }
    case ActionTypes.SAVE_CAPACITIES_TO_SESSION_ERROR:
      return { ...state, capacityError: true }
    case ActionTypes.FETCH_CAPACITIES_FROM_SESSION:
      return { ...state, capacities: payload, capacityError: false }
    case ActionTypes.FETCH_CAPACITIES_FROM_SESSION_ERROR:
      return { ...state, capacityError: true }
    default:
      return state
  }
}
