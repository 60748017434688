import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'

export const VariantContainer = styled.div`
  display: block;
  width: 30px;
  cursor: pointer;
  font-size: ${ndsTokens.font.size.text.large};
  border: 1px solid ${ndsTokens.colors.palette.gray.medium};
  padding-top: 10px;
  align-items: center;
  text-align: center;
  padding-bottom: 10px;
  padding-right: 80px;
  padding-left: 60px;
  border-radius: 5px;
  margin-bottom: 30px;
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    margin-bottom: 0;
  }
`

export const VariantGridContainer = styled.div`
  margin-left: 4px;
  margin-top: 40px;
  @media (min-width: ${ndsTokens.breakpoints.sm}) {
    margin-left: 18px;
  }
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding: 0;
    margin-top: 30px;
  }
`
