import styled from 'styled-components'

export const HeadingContainer = styled.div`
  margin-bottom: 30px;
  text-align: left;
`
export const NoProductContainer = styled.div`
  margin-top: 60px;
  text-align: center;
`
