import { Grid, Spinner } from '@nicollet/react'
import { useAnalytics } from '@praxis/component-analytics'
import { ResultDisplayCol, ResultDisplayRow } from './styles'
import { FilterValue } from '../../interfaces/apiModels/response/filter_value'
import { SelectionCard } from '../selectionCard'
import { LabeledImage } from '../labeledImage'
import { SearchResultParams } from './search-result-params'
import { Product } from '../../interfaces/apiModels/response/products/product'

export const SearchResults: React.FC<any> = ({
  filter_results,
  product_results,
  callBack,
  loading,
  searchLabel,
}: SearchResultParams) => {
  const { trackClick } = useAnalytics()

  const handleClick = (argument: any, itemName: string) => {
    trackClick(`Search_Result_${searchLabel}`, itemName)
    callBack(argument)
  }
  return (
    <Grid>
      <ResultDisplayRow>
        {loading && <Spinner size="large" />}

        {filter_results
          ? filter_results.map((filter_value: FilterValue, index: number) => (
              <ResultDisplayCol key={index} lg={2} md={4} xs={6}>
                <SelectionCard>
                  <LabeledImage
                    altText={filter_value.filter_value + ' image'}
                    callBack={() =>
                      handleClick(
                        filter_value.filter_value,
                        filter_value.filter_value,
                      )
                    }
                    imageUrl={filter_value.image_url}
                    label={filter_value.filter_value}
                  />
                </SelectionCard>
              </ResultDisplayCol>
            ))
          : product_results.map((product: Product, index: number) => (
              <ResultDisplayCol key={index} lg={2} md={4} xs={6}>
                <SelectionCard>
                  <LabeledImage
                    altText={product.product_title + ' image'}
                    callBack={() => handleClick(product, product.product_title)}
                    imageUrl={product.image_url}
                    label={product.product_title}
                  />
                </SelectionCard>
              </ResultDisplayCol>
            ))}
      </ResultDisplayRow>
    </Grid>
  )
}
