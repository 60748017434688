import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'

export const AddressContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 10px;
  position: relative;
  width: auto;
  background-color: ${ndsTokens.colors.palette.gray.lightest};
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  :hover {
    border-style: dashed;
    border-color: ${ndsTokens.colors.border.container.active};
    background-color: ${ndsTokens.colors.palette.gray.light};
  }
  @media (min-width: ${ndsTokens.breakpoints.sm}) {
    padding: 15px 20px 10px;
    margin: 15px auto 0px;
  }
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding-top: 0;
    margin-top: 0;
  }
`
