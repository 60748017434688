import { ActionTypes } from '../constants/action-types'
import { Storagekey } from '../constants/storage-keys'
import { Action } from '../interfaces/action'
import { sessionStorage } from '@web/storage'
import { Customer } from '../../interfaces/apiModels/response/order/customer'
import { AxiosRequestConfig } from 'axios'
import { axiosInstance } from '../../apis/axiosInstance'
import { Environments } from '../constants/environments'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'

export const saveCustomerToSessionStorage = (customer: Customer) => {
  return async (dispatch: any) => {
    try {
      await sessionStorage.set(Storagekey.CUSTOMER, customer)
      let action: Action = {
        type: ActionTypes.SAVE_CUSTOMER_TO_SESSION,
        payload: customer,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.SAVE_CUSTOMER_TO_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const fetchCustomerFromSessionStorage = () => {
  return async (dispatch: any) => {
    try {
      const value = await sessionStorage.get(Storagekey.CUSTOMER)
      let action: Action = {
        type: ActionTypes.FETCH_CUSTOMER_FROM_SESSION,
        payload: value,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.FETCH_CUSTOMER_FROM_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const fetchLoggedInCustomerData = (env) => {
  return async (dispatch: any) => {
    try {
      const existingCustomer: any = await sessionStorage.get(
        Storagekey.TGT_CUSTOMER,
      )
      if (existingCustomer?.address) {
        let action: Action = {
          type: ActionTypes.FETCH_LOGGED_IN_CUSTOMER,
          payload: existingCustomer.address,
        }
        dispatch(action)
      } else {
        let axiosRequest: AxiosRequestConfig
        if (env.environment === Environments.LOCAL) {
          axiosRequest = {
            baseURL: '.',
            url: '/mock_data/target/getCustomerAddressResponse.json',
            responseType: 'json',
          }
        } else {
          const baseURL = env.target.guest_details_v1.baseUrl //https://api.target.com for prod https://stgapi-internal.target.com/ for stage

          axiosRequest = {
            method: 'get',
            baseURL: baseURL,
            url: '/guest_addresses/v1/addresses',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
            },
            params: {
              address_type: 'S',
              default_address: true,
            },
            responseType: 'json',
          }
        }

        const response = await axiosInstance.request(axiosRequest)

        await sessionStorage.set(Storagekey.TGT_CUSTOMER, response.data)

        let action: Action = {
          type: ActionTypes.FETCH_LOGGED_IN_CUSTOMER,
          payload: response.data.address,
        }
        dispatch(action)
      }
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.FETCH_LOGGED_IN_CUSTOMER_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const fetchIdToken = (env) => {
  return async (dispatch: any) => {
    try {
      const tokenData: any = await sessionStorage.get(
        Storagekey.TGT_CUSTOMER_ID_TOKEN,
      )
      if (tokenData?.sub) {
        let action: Action = {
          type: ActionTypes.FETCH_ID_TOKEN,
          payload: tokenData,
        }
        dispatch(action)
      } else {
        let tokenData
        if (env.environment === Environments.LOCAL) {
          tokenData = {
            sub: '20021017010',
            pro: {
              em: 'Tommy.Tutone@target.com',
            },
          }
        } else {
          const idTokenName = env.target.guest_details_v1.idTokenName

          let token = Cookies.get(idTokenName) // stage_tdc-idToken in Stage, idToken in Prod
          tokenData = jwt_decode(token)
        }

        await sessionStorage.set(Storagekey.TGT_CUSTOMER_ID_TOKEN, tokenData)

        let action: Action = {
          type: ActionTypes.FETCH_ID_TOKEN,
          payload: tokenData,
        }
        dispatch(action)
      }
    } catch (ignore) {
      // ignore
    }
  }
}
