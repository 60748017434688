import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { StorageState } from '../interfaces/storage_state'

const initialState: StorageState = {
  clearStorageError: false,
}
export const storageReducer = (
  state: StorageState = initialState,
  { type }: Action,
) => {
  switch (type) {
    case ActionTypes.CLEAR_SESSION_STORAGE:
      return { ...state, clearStorageError: false }
    case ActionTypes.CLEAR_SESSION_STORAGE_ERROR:
      return { ...state, clearStorageError: true }
    default:
      return state
  }
}
