export const validateIsNumber = (number: string) => number.match(/^[1-9]\d*$/)

export const sanitizePhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/\D/g, '')

export const validateIsPhoneNumber = (phoneNumber: string): boolean =>
  sanitizePhoneNumber(phoneNumber).match(/^\d{10}$/) !== null

export const validateIsEmail = (email: string): boolean =>
  email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/) !== null

export const validateIsName = (name: string): boolean =>
  name.match(/^[ A-Za-z]+$/) !== null

export const validateIsZipCode = (zipCode: string): boolean =>
  zipCode.match(/^\d{5}(-|\s*)?(\d{4})?$/) !== null

export const validateAddressLine1 = (value: string): boolean =>
  value?.length > 0

export const validateState = (value: string): boolean => value?.length > 0
