import { Home } from '../home'
import { SearchDevice } from '../searchDevice'
import { StyledSearchContainer } from './styles'

export const Search = () => {
  return (
    <>
      {Home('search')}
      <StyledSearchContainer>
        <SearchDevice />
      </StyledSearchContainer>
    </>
  )
}
