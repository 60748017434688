export enum Paths {
  TargetUatHome = 'https://www-uat.target.com/',
  TargetHome = 'https://www.target.com/',
  Home = '/home',
  SearchResults = '/search-results',
  DeviceConditions = '/device-conditions',
  PersonalInformation = '/personal-info',
  Search = '/search',
  Categories = '/',
  Category = '/browse/category',
  Manufacturers = '/browse/category/:categoryId/manufacturer',
  Devices = '/browse/category/:categoryId/manufacturer/:manufacturerId/device',
  Capacity = '/browse/category/:categoryId/manufacturer/:manufacturerId/device/:deviceId/capacity',
  Models = '/browse/category/:categoryId/manufacturer/:manufacturerId/device/:deviceId/capacity/:capacityId/model',
  Review = '/review',
  TradeComplete = '/trade-complete',
}
