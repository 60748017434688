import { Grid, Row, Spinner } from '@nicollet/react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Paths } from '../../enums/paths'
import { Product } from '../../interfaces/apiModels/response/products/product'
import { Filters } from '../../interfaces/filters'
import { FilterType } from '../../interfaces/filter_type'
import { fetchingModels, fetchModels } from '../../store/actions/modelActions'
import { saveProductToSessionStorage } from '../../store/actions/productSelectedStorageActions'
import { ModelState } from '../../store/interfaces/model_state'
import { RootState } from '../../store/reducers'
import { BrowseTypeGridContainer } from '../../../common/styles/global-styles'
import { DisplayDeviceModel } from '../displayDeviceModel'
import { BaseURLState } from '../../store/interfaces/base_url_state'
import { fetchBaseURLFromSessionStorage } from '../../store/actions/baseURLStorageActions'
import { Home } from '../home'
import { GenericErrorContainer } from '../styles'
import { GENERIC_ERROR_MESSAGE } from '../../constants/common-constants'

export const Models = () => {
  let navigate = useNavigate()
  let location = useLocation()
  let category = ''
  let manufacturer = ''
  let device = ''
  let capacity = ''

  const perPage: number = 6 // FIXME once we decide the approach

  const dispatch = useDispatch()

  let baseURLDetails: BaseURLState = useSelector((state: RootState) => {
    return state.baseURL
  })
  let { baseURL, baseURLExist } = baseURLDetails

  useEffect(() => {
    dispatch(fetchBaseURLFromSessionStorage())
  }, [])

  let path = location.pathname.split('/', 11)
  category = path[3] // iPhone
  manufacturer = path[5] // Apple
  device = path[7] // iphone 11
  capacity = path[9] // 32GB

  useEffect(() => {
    if (baseURLExist) {
      getProducts(1)
    }
  }, [baseURLExist])

  const getProducts = (page: number) => {
    dispatch(fetchingModels())

    // Setup filters to load products
    let filters: Filters = {
      filters: [
        { name: FilterType.CATEGORY, value: category },
        { name: FilterType.MANUFACTURER, value: manufacturer },
        { name: FilterType.PRODUCT_FAMILY, value: device },
        {
          name: FilterType.CAPACITY,
          value: capacity !== '0' ? capacity : '',
        },
      ],
    }
    dispatch(fetchModels(baseURL, page, perPage, filters))
  }

  let allProducts: ModelState = useSelector((state: RootState) => {
    return state.models
  })

  let { count, models, loading, modelError, modelsEmpty } = allProducts

  const routeNext = (product: Product) => {
    dispatch(saveProductToSessionStorage(product))
    navigate(
      Paths.DeviceConditions + '?id=' + product.tradein_provider_product_id,
    )
  }

  return (
    <>
      {Home('browse')}
      <BrowseTypeGridContainer>
        {modelError && (
          <GenericErrorContainer
            className="errors"
            role="alert"
            aria-relevant="all"
            tabIndex={0}
          >
            {GENERIC_ERROR_MESSAGE}
          </GenericErrorContainer>
        )}
        {!modelError && !modelsEmpty ? (
          <Grid>
            <Row>
              {loading && <Spinner size="large" />}
              <DisplayDeviceModel
                onChangeCallBack={getProducts}
                onProductSelect={routeNext}
                perPage={perPage}
                deviceModels={models}
                modelsCount={count}
              />
            </Row>
          </Grid>
        ) : (
          ''
        )}
      </BrowseTypeGridContainer>
    </>
  )
}
