import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { CustomerState } from '../interfaces/customer_state'

const initialState: CustomerState = {
  customer: {
    first_name: '',
    last_name: '',
    email: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    phone: '',
  },
  targetCustomerAddress: {},
  idTokenData: {},
  error: false,
}
export const customerReducer = (
  state: CustomerState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionTypes.SAVE_CUSTOMER_TO_SESSION:
      return { ...state, customer: payload, error: false }
    case ActionTypes.SAVE_CUSTOMER_TO_SESSION_ERROR:
      return { ...state, error: true }
    case ActionTypes.FETCH_CUSTOMER_FROM_SESSION:
      return { ...state, customer: payload, error: false }
    case ActionTypes.FETCH_CUSTOMER_FROM_SESSION_ERROR:
      return { ...state, error: true }
    case ActionTypes.FETCH_ID_TOKEN:
      return { ...state, idTokenData: payload, error: false }
    case ActionTypes.FETCH_LOGGED_IN_CUSTOMER:
      return { ...state, targetCustomerAddress: payload, error: false }
    case ActionTypes.FETCH_LOGGED_IN_CUSTOMER_ERROR:
      return { ...state, error: true }
    default:
      return state
  }
}
