export const ActionTypes = {
  // Fetching models from api response
  FETCHING_MODELS: 'FETCHING_MODELS',
  FETCH_MODELS: 'FETCH_MODELS',
  FETCH_MODELS_ERROR: 'FETCH_MODELS_ERROR',
  FETCHING_POPULAR_MODELS: 'FETCHING_POPULAR_MODELS',
  FETCH_POPULAR_MODELS: 'FETCH_POPULAR_MODELS',
  FETCH_POPULAR_MODELS_ERROR: 'FETCH_POPULAR_MODELS_ERROR',

  // Fetching product categories
  FETCHING_CATEGORIES: 'FETCHING_CATEGORIES',
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  FETCH_CATEGORIES_ERROR: 'FETCH_CATEGORIES_ERROR',

  // Fetching manufacturers
  FETCHING_MANUFACTURERS: 'FETCHING_MANUFACTURERS',
  FETCH_MANUFACTURERS: 'FETCH_MANUFACTURERS',
  FETCH_MANUFACTURERS_ERROR: 'FETCH_MANUFACTURERS_ERROR',

  // Fetching devices
  FETCHING_DEVICES: 'FETCHING_DEVICES',
  FETCH_DEVICES: 'FETCH_DEVICES',
  FETCH_DEVICES_ERROR: 'FETCH_DEVICES_ERROR',

  // Fetching CAPACITTIES
  FETCHING_CAPACITIES: 'FETCHING_CAPACITIES',
  FETCH_CAPACITIES: 'FETCH_CAPACITIES',
  FETCH_CAPACITIES_ERROR: 'FETCH_CAPACITIES_ERROR',

  // Fetch questions to display from device questions api reesponse
  FETCHING_DEVICE_QUESTIONS: 'FETCHING_DEVICE_QUESTIONS',
  FETCH_DEVICE_QUESTIONS: 'FETCH_DEVICE_QUESTIONS',
  FETCH_DEVICE_QUESTIONS_ERROR: 'FETCHING_DEVICE_QUESTIONS_ERROR',

  // call quote api to get updated price
  POST_QUOTE: 'POST_QUOTE',
  POST_QUOTE_REFRESH: 'POST_QUOTE_REFRESH',
  POST_QUOTE_LOAD: 'POST_QUOTE_LOAD',
  POST_QUOTE_ERROR: 'POST_QUOTE_ERROR',

  // call order api
  POSTING_ORDER: 'POSTING_OORDER',
  POST_ORDER_REFRESH: 'POST_ORDER_REFRESH',
  POST_ORDER: 'POST_ORDER',
  POST_ORDER_ERROR: 'POST_ORDER_ERROR',

  // Update user answers to build request for quote api
  UPDATE_QUOTE_REQUEST_ANSWER: 'UPDATE_QUOTE_REQUEST_ANSWER',

  // Save and fetch product from session storage
  SAVE_PRODUCT_TO_SESSION: 'SAVE_PRODUCT_TO_SESSION',
  SAVE_PRODUCT_TO_SESSION_ERROR: 'SAVE_PRODUCT_TO_SESSION_ERROR',
  FETCH_PRODUCT_FROM_SESSION: 'FETCH_PRODUCT_FROM_SESSION',
  FETCH_PRODUCT_FROM_SESSION_ERROR: 'FETCH_PRODUCT_FROM_SESSION_ERROR',
  REMOVE_SELECTED_PRODUCT: 'REMOVE_SELECTED_PRODUCT',

  // Save and fetch customer from session storage
  SAVE_CUSTOMER_TO_SESSION: 'SAVE_CUSTOMER_TO_SESSION',
  SAVE_CUSTOMER_TO_SESSION_ERROR: 'SAVE_CUSTOMER_TO_SESSION_ERROR',
  FETCH_CUSTOMER_FROM_SESSION: 'FETCH_CUSTOMER_FROM_SESSION',
  FETCH_CUSTOMER_FROM_SESSION_ERROR: 'FETCH_CUSTOMER_FROM_SESSION_ERROR',
  FETCH_ID_TOKEN: 'FETCH_ID_TOKEN',
  FETCH_LOGGED_IN_CUSTOMER: 'FETCH_LOGGED_IN_CUSTOMER',
  FETCH_LOGGED_IN_CUSTOMER_ERROR: 'FETCH_LOGGED_IN_CUSTOMER_ERROR',

  // Save and fetch device number from session storage
  SAVE_DEVICE_NUMBER_TO_SESSION: 'SAVE_DEVICE_NUMBER_TO_SESSION',
  SAVE_DEVICE_NUMBER_TO_SESSION_ERROR: 'SAVE_DEVICE_NUMBER_TO_SESSION_ERROR',
  FETCH_DEVICE_NUMBER_FROM_SESSION: 'FETCH_DEVICE_NUMBER_FROM_SESSION',
  FETCH_DEVICE_NUMBER_FROM_SESSION_ERROR:
    'FETCH_DEVICE_NUMBER_FROM_SESSION_ERROR',

  // Save and fetch final price from session storage
  SAVE_PRICE_RESPONSE_TO_SESSION: 'SAVE_PRICE_RESPONSE_TO_SESSION',
  SAVE_PRICE_RESPONSE_TO_SESSION_ERROR: 'SAVE_PRICE_RESPONSE_TO_SESSION_ERROR',
  FETCH_PRICE_RESPONSE_FROM_SESSION: 'FETCH_PRICE_RESPONSE_FROM_SESSION',
  FETCH_PRICE_RESPONSE_FROM_SESSION_ERROR:
    'FETCH_PRICE_RESPONSE_FROM_SESSION_ERROR',

  // Save and fetch product from session storage
  SAVE_DEVICE_CONDITIONS_RESPONSE_TO_SESSION:
    'SAVE_DEVICE_CONDITIONS_RESPONS_TO_SESSION',
  SAVE_DEVICE_CONDITIONS_RESPONSE_TO_SESSION_ERROR:
    'SAVE_DEVICE_CONDITIONS_RESPONSE_TO_SESSION_ERROR',
  FETCH_DEVICE_CONDITIONS_RESPONSE_FROM_SESSION:
    'FETCH_DEVICE_CONDITIONS_RESPONSE_FROM_SESSION',
  FETCH_DEVICE_CONDITIONS_RESPONSE_FROM_SESSION_ERROR:
    'FETCH_DEVICE_CONDITIONS_RESPONSE_FROM_SESSION_ERROR',

  // Save and fetch ORDERID from session storage
  SAVE_ORDER_ID_TO_SESSION: 'SAVE_ORDER_ID_TO_SESSION',
  SAVE_ORDER_ID_TO_SESSION_ERROR: 'SAVE_ORDER_ID_TO_SESSION_ERROR',
  FETCH_ORDER_ID_FROM_SESSION: 'FETCH_ORDER_ID_FROM_SESSION',
  FETCH_ORDER_ID_FROM_SESSION_ERROR: 'FETCH_ORDER_ID_FROM_SESSION_ERROR',

  CLEAR_SESSION_STORAGE: 'CLEAR_SESSION_STORAGE',
  CLEAR_SESSION_STORAGE_ERROR: 'CLEAR_SESSION_STORAGE_ERROR',

  // Save and fetch capacitties from session storage
  SAVE_CAPACITIES_TO_SESSION: 'SAVE_CAPACITIES_TO_SESSION',
  SAVE_CAPACITIES_TO_SESSION_ERROR: 'SAVE_CAPACITIES_TO_SESSION_ERROR',
  FETCH_CAPACITIES_FROM_SESSION: 'FETCH_CAPACITIES_FROM_SESSION',
  FETCH_CAPACITIES_FROM_SESSION_ERROR: 'FETCH_CAPACITIES_FROM_SESSION_ERROR',

  SAVE_BASE_URL_TO_SESSION: 'SAVE_BASE_URL_TO_SESSION',
  SAVE_BASE_URL_TO_SESSION_ERROR: 'SAVE_BASE_URL_TO_SESSION_ERROR',
  FETCH_BASE_URL_FROM_SESSION: 'FETCH_BASE_URL_FROM_SESSION',
  FETCH_BASE_URL_FROM_SESSION_ERROR: 'FETCH_BASE_URL_FROM_SESSION_ERROR',

  // Save and fetch keyword search results from session storage
  SAVE_SEARCH_RESULTS_TO_SESSION: 'SAVE_SEARCH_RESULTS_TO_SESSION',
  SAVE_SEARCH_RESULTS_TO_SESSION_ERROR: 'SAVE_SEARCH_RESULTS_TO_SESSION_ERROR',
  FETCH_SEARCH_RESULTS_FROM_SESSION: 'FETCH_SEARCH_RESULTS_FROM_SESSION',
  FETCH_SEARCH_RESULTS_FROM_SESSION_ERROR:
    'FETCH_SEARCH_RESULTS_FROM_SESSION_ERROR',
}
