import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { ProductSelectionState } from '../interfaces/product_selection_state'

const initialState: ProductSelectionState = {
  selectedProduct: {
    tradein_provider: '',
    tradein_provider_product_id: '',
    product_title: '',
    image_url: '',
    manufacturer: '',
    product_currency: '',
    tradein_max_price: 0,
    attributes: [],
  },
  error: false,
}
export const productSelectedStorageReducer = (
  state: ProductSelectionState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionTypes.SAVE_PRODUCT_TO_SESSION:
      return { ...state, selectedProduct: payload, error: false }
    case ActionTypes.SAVE_PRODUCT_TO_SESSION_ERROR:
      return { ...state, error: true }
    case ActionTypes.FETCH_PRODUCT_FROM_SESSION:
      return { ...state, selectedProduct: payload, error: false }
    case ActionTypes.FETCH_PRODUCT_FROM_SESSION_ERROR:
      return { ...state, error: true }
    default:
      return state
  }
}
