import { useNavigate, useLocation } from 'react-router-dom'
import { BrowseTypeGridContainer } from '../../../common/styles/global-styles'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/reducers'
import {
  fetchingManufacturers,
  fetchManufacturers,
} from '../../store/actions/manufacturerActions'
import { ManufacturerState } from '../../store/interfaces/manufacturer_state'
import { Filters } from '../../interfaces/filters'
import { FilterType } from '../../interfaces/filter_type'
import { MANUFACTURER_DISPLAY_LIMIT } from '../../constants'
import { useEffect } from 'react'
import { SearchResults } from '../searchResults'
import { BaseURLState } from '../../store/interfaces/base_url_state'
import { fetchBaseURLFromSessionStorage } from '../../store/actions/baseURLStorageActions'
import { Home } from '../home'
import { GenericErrorContainer } from '../styles'
import { GENERIC_ERROR_MESSAGE } from '../../constants/common-constants'

export const Manufacturers = () => {
  let navigate = useNavigate()
  let location = useLocation()

  const dispatch = useDispatch()

  // The entry point of the component, the code inside will be called
  // to do any processing, such as loading data from backend.
  let baseURLDetails: BaseURLState = useSelector((state: RootState) => {
    return state.baseURL
  })
  let { baseURL, baseURLExist } = baseURLDetails

  useEffect(() => {
    dispatch(fetchBaseURLFromSessionStorage())
  }, [])

  useEffect(() => {
    if (baseURLExist) {
      dispatch(fetchingManufacturers())
      // Example: /browse/category/iPhone/manufacturer
      let splitted = location.pathname.split('/', 5)

      let category = splitted[3]

      let filters: Filters = {
        filters: [{ name: FilterType.CATEGORY, value: category }],
      }
      dispatch(fetchManufacturers(baseURL, filters, MANUFACTURER_DISPLAY_LIMIT))
    }
  }, [baseURLExist])

  let manufacturersState: ManufacturerState = useSelector(
    (state: RootState) => {
      return state.manufacturers
    },
  )

  let { manufacturers, loading, manufacturerError, manufacturersEmpty } =
    manufacturersState

  // When manufacturer image is clicked, it goes to the device page,
  // http://localhost:3000/browse/category/iPhone/manufacturer/Apple/device
  const onChange = (manufacturer: string) => {
    navigate(location.pathname + '/' + manufacturer + '/device')
  }

  return (
    <>
      {Home('browse')}
      <BrowseTypeGridContainer>
        {manufacturerError && (
          <GenericErrorContainer
            className="errors"
            role={manufacturerError ? 'alert' : 'none'}
            tabIndex={0}
          >
            {GENERIC_ERROR_MESSAGE}
          </GenericErrorContainer>
        )}
        {!manufacturerError && !manufacturersEmpty ? (
          <SearchResults
            filter_results={manufacturers}
            callBack={onChange}
            loading={loading}
            searchLabel={'Browse_Manufacturer'}
          />
        ) : (
          ''
        )}
      </BrowseTypeGridContainer>
    </>
  )
}
