import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  fetchCategories,
  fetchingCategories,
} from '../../store/actions/categoryActions'
import { CategoryState } from '../../store/interfaces/category_state'
import { RootState } from '../../store/reducers'
import { BrowseTypeGridContainer } from '../../../common/styles/global-styles'
import { SearchResults } from '../../components/searchResults'
import { BaseURLState } from '../../store/interfaces/base_url_state'
import { fetchBaseURLFromSessionStorage } from '../../store/actions/baseURLStorageActions'
import { Home } from '../../components/home'
import { GenericErrorContainer } from '../../components/styles'
import { GENERIC_ERROR_MESSAGE } from '../../constants/common-constants'

export const Categories = () => {
  let navigate = useNavigate()

  const dispatch = useDispatch()

  // The entry point of the component, the code inside will be called
  // to do any processing, such as loading data from backend.
  let baseURLDetails: BaseURLState = useSelector((state: RootState) => {
    return state.baseURL
  })
  let { baseURL, baseURLExist } = baseURLDetails

  useEffect(() => {
    dispatch(fetchBaseURLFromSessionStorage())
  }, [])

  useEffect(() => {
    if (baseURLExist) {
      dispatch(fetchingCategories())
      dispatch(fetchCategories(baseURL))
    }
  }, [baseURLExist])

  let categoriesState: CategoryState = useSelector((state: RootState) => {
    return state.categories
  })

  let { categories, loading, categoryError, categoriesEmpty } = categoriesState

  // When category image is clicked, it goes to the manufacturer page,
  // for example: http://localhost:3000/browse/category/iPhone/manufacturer
  const onChange = (category: string) => {
    navigate(`/browse/category/${category}/manufacturer`)
  }

  return (
    <>
      {Home('browse')}
      <BrowseTypeGridContainer>
        {categoryError && (
          <GenericErrorContainer
            className="errors"
            role="alert"
            aria-relevant="all"
            tabIndex={0}
          >
            {GENERIC_ERROR_MESSAGE}
          </GenericErrorContainer>
        )}
        {!categoryError && !categoriesEmpty ? (
          <SearchResults
            filter_results={categories}
            callBack={onChange}
            loading={loading}
            searchLabel={'Browse_Category'}
          />
        ) : (
          ''
        )}
      </BrowseTypeGridContainer>
    </>
  )
}
