import { Route, Routes } from 'react-router-dom'
import { AnalyticsProvider } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { SearchResults } from './views/searchResults'
import { DeviceConditions } from './views/deviceConditions'
import { PersonalInformation } from './views/personalInformation'
import { TradeInReview } from './views/review'
import { Categories } from './views/categories'
import { Manufacturers } from './components/manufacturers'
import { Paths } from './enums/paths'
import { TradeComplete } from './views/tradeComplete'
import { Devices } from './components/devices'
import { Capacity } from './components/capacity'
import { Search } from './components/search'
import { Models } from './components/models'
import ErrorBoundary from './components/errorBoundary'
import { ErrorPageNotFound } from './components/pageNotFound'
import { CustomAnalyticsProvider } from './components/customAnalyticsProvider'

export const pageRoutes: Array<LooseObject> = [
  // {
  //   path: Paths.LandingHome,
  //   component: Categories, // Change this to LandingHome to enable the landing page
  //   exact: true,
  //   analyticsPageName: 'Home',
  // },
  {
    path: Paths.Home,
    element: <Categories />,
    analyticsPageName: 'Home',
  },
  {
    path: Paths.Category,
    element: <Categories />,
    analyticsPageName: 'Browse Categories',
  },
  {
    path: Paths.Search,
    element: <Search />,
    analyticsPageName: 'Home',
  },
  {
    path: Paths.SearchResults,
    element: <SearchResults />,
    analyticsPageName: 'Text Search Results',
  },
  {
    path: Paths.DeviceConditions,
    element: <DeviceConditions />,
    analyticsPageName: 'Device Conditions',
  },
  {
    path: Paths.PersonalInformation,
    element: <PersonalInformation />,
    analyticsPageName: 'Personal Information',
  },
  {
    path: Paths.Review,
    element: <TradeInReview />,
    analyticsPageName: 'Review Trade In',
  },
  {
    path: Paths.TradeComplete,
    element: <TradeComplete />,
    analyticsPageName: 'Trade Complete',
  },
  {
    path: Paths.Categories,
    element: <Categories />,
    analyticsPageName: 'Browse Categories',
  },
  {
    path: Paths.Manufacturers,
    element: <Manufacturers />,
  },
  {
    path: Paths.Devices,
    element: <Devices />,
  },
  {
    path: Paths.Capacity,
    element: <Capacity />,
  },
  {
    path: Paths.Models,
    element: <Models />,
  },
  {
    path: '*',
    element: <ErrorPageNotFound />,
    analyticsPageName: 'Page Not Found',
  },
]

const Routing = ({ env }) => {
  return (
    <AnalyticsProvider eventManagerConfiguration={env.analytics}>
      <ErrorBoundary>
        <CustomAnalyticsProvider />
        <Routes>
          {pageRoutes.map((route, index) => (
            <Route key={index} {...route} />
          ))}
        </Routes>
      </ErrorBoundary>
    </AnalyticsProvider>
  )
}

export default withEnv()(Routing)
