import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'

export const PaginationContainer = styled.div`
  bottom: 20px;
  width: 100%;
`

export const StyledImageContainer = styled.div`
  margin-bottom: 30px;
  margin-right: 10px;
  text-align: center;
  align-items: center;
`

export const StyledImg = styled.img`
  margin-bottom: 20px;
  cursor: pointer;
  width: 120px;
  text-align: center;
  display: inline;
`

export const StyledGridContainer = styled.div`
  margin: 15px auto;
  width: 100%;
  max-width: 1128px;
  @media (min-width: ${ndsTokens.breakpoints.sm}) {
    padding: 15px 20px;
  }
`

export const RefinedSpan = styled.span`
  display: block;
  width: 80%;
  margin: 0 10%;
  text-align: center;
  font-size: ${ndsTokens.font.size.text.large};
  font-weight: 500;
`
