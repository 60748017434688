export const SEARCH_BY_DEVICE_BUTTON_LABEL: string = 'Search by device name'
export const BROWSE_BY_CATEGORY_BUTTON_LABEL: string = 'Browse by category'

export const CLIENT_ID: string = 'ecom-web-1.0.0'
export const PER_PAGE_RESULTS: number = 12

export const GENERIC_ERROR_MESSAGE: string =
  'Something went wrong, please try again later'
export const TEST_HEADER: string = 'synth_IZLPRo79Xp'
export const TEST_CLIENT_ID: string = 'mi6-test-1.0.0'
// TODO: move this to config
export const TEST_OWNER_ID: string =
  'connectedcommercetradeintest@perftarget.com'
export const TEST_OWNER_SECRET: string = 'TradeinTest4'
