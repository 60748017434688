import { TandCParams } from './tcParams'
import { ButtonSpecialtyRed, Col, Grid, Row } from '@nicollet/react'
import { ConditionsText } from './conditionsText'
import { HiddenIFrame, LowerLeftButton, LowerRightButton } from './styles'
import { TradeInProvider } from '../../enums/tradein_provider'

export const TermsAndConditions: React.FC<TandCParams> = ({ provider }) => {
  let pdfName
  switch (provider) {
    case TradeInProvider.Assurant:
    default:
      pdfName = 'AssurantTC.pdf'
  }

  const printPDF = () => {
    let content = document.getElementById('contract')
    // @ts-ignore
    let pri = document.getElementById('frameForPrintingPdf').contentWindow
    pri.document.open()
    // @ts-ignore
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()
  }

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <ConditionsText provider={provider} />
        </Col>
      </Row>
      <Row center={'xs'}>
        <LowerLeftButton lg={6} xs={12}>
          <a href={'./contracts/' + pdfName} download={'Assurant_Contract.pdf'}>
            <ButtonSpecialtyRed isFullWidth>Save as PDF</ButtonSpecialtyRed>
          </a>
        </LowerLeftButton>
        <LowerRightButton lg={6} xs={12}>
          <ButtonSpecialtyRed isFullWidth onClick={printPDF}>
            Print
          </ButtonSpecialtyRed>
        </LowerRightButton>
      </Row>
      <HiddenIFrame id="frameForPrintingPdf" />
    </Grid>
  )
}
