import { ActionTypes } from '../constants/action-types'
import { Storagekey } from '../constants/storage-keys'
import { Action } from '../interfaces/action'
import { sessionStorage } from '@web/storage'

export const saveBaseURLToSessionStorage = (baseURL) => {
  return async (dispatch: any) => {
    try {
      await sessionStorage.set(Storagekey.BASE_URL, baseURL)
      let action: Action = {
        type: ActionTypes.SAVE_BASE_URL_TO_SESSION,
        payload: baseURL,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.SAVE_BASE_URL_TO_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const fetchBaseURLFromSessionStorage = () => {
  return async (dispatch: any) => {
    try {
      const value = await sessionStorage.get(Storagekey.BASE_URL)
      let action: Action = {
        type: ActionTypes.FETCH_BASE_URL_FROM_SESSION,
        payload: value,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.FETCH_BASE_URL_FROM_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}
