import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAnalytics } from '@praxis/component-analytics'
import { pageRoutes } from '../../routing'

export const CustomAnalyticsProvider = () => {
  let location = useLocation()

  let browsePageURL = '/browse/category'
  const { trackEvent, trackView } = useAnalytics()

  const [counter, setCounter] = useState(new Date().getTime())
  const [oldLocation, setOldLocation] = useState('')

  const pageTimeAnalytics = {
    event: {
      name: 'page_browse_time',
      type: 'seconds_on_page',
    },
    user: {},
  }

  useEffect(() => {
    try {
      if (
        oldLocation !== '' &&
        !location.pathname.includes(browsePageURL + '/')
      ) {
        pageTimeAnalytics.user['customDimension1'] = (
          (new Date().getTime() - counter) /
          1000
        ).toFixed(2)
        pageTimeAnalytics.user['customDimension2'] = oldLocation

        trackEvent(pageTimeAnalytics)
      }

      for (const route of pageRoutes) {
        if (route.path === location.pathname) {
          setOldLocation(route.analyticsPageName)
          setCounter(new Date().getTime())
          trackView(route.analyticsPageName)
          break
        }
      }
    } catch (ignore) {
      //ignore
    }
  }, [location])

  return <span />
}
