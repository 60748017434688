import { CLIENT_ID } from '../constants/common-constants'
import Cookies from 'js-cookie'
import { functionToRetrieveFromConfigFile } from '../../common/apis/storeApi'
import { axiosInstance } from '../apis/axiosInstance'
import { Paths } from '../enums/paths'
import { StatusCodes } from 'http-status-codes'

export const handleException = (errorResponse: any) => {
  if (errorResponse?.status === StatusCodes.UNAUTHORIZED) {
    handleUnauthorizedError()
  }
}

const handleUnauthorizedError = () => {
  functionToRetrieveFromConfigFile().then((response) => {
    let gspAuthHost = response.gsp.url
    let code = new URLSearchParams(window.location.search).get('code')
    let state = new URLSearchParams(window.location.search).get('state')
    if (!code && !state) {
      const parsedIdToken = getIdToken(gspAuthHost)
      const mystate = Date.now()
      let redirectUrl = window.location.href
      let encodedUrl = encodeURIComponent(redirectUrl)
      let urlParams = `client_id=${CLIENT_ID}&state=${mystate}&redirect_uri=${encodedUrl}`
      if (parsedIdToken) {
        urlParams = urlParams + `&assurance_level=${parsedIdToken.ass}`
      }
      window.location.href = `${gspAuthHost}/gsp/authentications/v1/auth_codes?${urlParams}`
    } else if (code && state) {
      postClientTokens(gspAuthHost, code)
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          goToTarget(gspAuthHost)
        })
    } else {
      goToTarget(gspAuthHost)
    }
  })
}

const goToTarget = (gspAuthHost: string) => {
  if (gspAuthHost.indexOf('perf') > -1) {
    window.location.href = Paths.TargetUatHome
  } else {
    window.location.href = Paths.TargetHome
  }
}

const getIdToken = (host: string) => {
  let idToken = ''
  if (host.indexOf('perf') > -1) {
    idToken = Cookies.get('stage_tdc-idToken')
  } else {
    idToken = Cookies.get('idToken')
  }
  if (idToken) {
    const base64Url = idToken.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  } else {
    return ''
  }
}

const postClientTokens = async (gspAuthHost: string, code: string) => {
  let requestInfo = {
    grant_type: 'authorization_code',
    client_credential: {
      client_id: CLIENT_ID,
    },
    code: code,
  }
  return await axiosInstance.post(
    `${gspAuthHost}/gsp/oauth_tokens/v2/client_tokens`,
    requestInfo,
  )
}
