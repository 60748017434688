import { Col, Grid, Row, Spinner } from '@nicollet/react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { FilterValue } from '../../interfaces/apiModels/response/filter_value'
import { fetchCapacitiesFromSessionStorage } from '../../store/actions/capacityActions'
import { CapacityState } from '../../store/interfaces/capacity_state'
import { RootState } from '../../store/reducers'
import { BrowseTypeGridContainer } from '../../../common/styles/global-styles'
import { Home } from '../home'
import { VariantGridContainer, VariantContainer } from './styles'
import { GenericErrorContainer } from '../styles'

export const Capacity = () => {
  let navigate = useNavigate()
  let location = useLocation()

  const dispatch = useDispatch()

  let modelVariantsState: CapacityState = useSelector((state: RootState) => {
    return state.capacities
  })

  let { capacities, loading, capacityError, capacitiesEmpty } =
    modelVariantsState

  useEffect(() => {
    dispatch(fetchCapacitiesFromSessionStorage())
  }, [])

  // When capacity is clicked, it goes to the model page,
  // http://localhost:3000/browse/category/iPhone/manufacturer/Apple/device/iphone11/variant/64GB/model
  const onChange = (capacity: string) => {
    navigate(location.pathname + '/' + capacity + '/model')
  }

  return (
    <>
      {Home('browse')}
      <BrowseTypeGridContainer>
        {capacityError && (
          <GenericErrorContainer>
            Something went wrong, please try again later
          </GenericErrorContainer>
        )}
        {!capacityError && !capacitiesEmpty ? (
          <div>
            <Grid>
              <Row>
                {loading && <Spinner size="large" />}
                {capacities.map((filter_value: FilterValue, index: number) => (
                  <Col key={index} lg={2} md={3} xs={6}>
                    <VariantGridContainer>
                      <VariantContainer
                        aria-label={filter_value.filter_value + ' link'}
                        tabIndex={0}
                        onKeyPress={() => onChange(filter_value.filter_value)}
                        onClick={() => onChange(filter_value.filter_value)}
                      >
                        {filter_value.filter_value}
                      </VariantContainer>
                    </VariantGridContainer>
                  </Col>
                ))}
              </Row>
            </Grid>
          </div>
        ) : (
          ''
        )}
      </BrowseTypeGridContainer>
    </>
  )
}
