export const Storagekey = {
  PRODUCT_SELECTED: 'PRODUCT_SELECTED',
  CUSTOMER: 'CUSTOMER',
  TGT_CUSTOMER: 'TGT_CUSTOMER',
  TGT_CUSTOMER_ID_TOKEN: 'TGT_CUSTOMER_ID_TOKEN',
  DEVICE_NUMBER: 'DEVICE_NUMBER',
  QUOTE_RESPONSE: 'QUOTE_RESPONSE',
  DEVICE_CONDITIONS_RESPONSE: 'DEVICE_CONDITIONS_RESPONSE',
  ORDER_ID: 'ORDER_ID',
  CAPACITIES: 'CAPACITIES',
  BASE_URL: 'BASE_URL',
  KEYWORD_SEARCH_RESULTS: 'KEYWORD_SEARCH_RESULTS',
}
