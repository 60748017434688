import { isNullOrEmpty } from '../../utils/commonUtils'
import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { BaseURLState } from '../interfaces/base_url_state'

const initialState: BaseURLState = {
  baseURL: '',
  baseURLExist: false,
  baseURLError: false,
}
export const baseURLStorageReducer = (
  state: BaseURLState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionTypes.SAVE_BASE_URL_TO_SESSION:
      return {
        ...state,
        baseURL: payload,
        baseURLError: false,
        baseURLExist: !isNullOrEmpty(payload),
      }
    case ActionTypes.SAVE_BASE_URL_TO_SESSION_ERROR:
      return { ...state, baseURLError: true }
    case ActionTypes.FETCH_BASE_URL_FROM_SESSION:
      return {
        ...state,
        baseURL: payload,
        baseURLError: false,
        baseURLExist: !isNullOrEmpty(payload),
      }
    case ActionTypes.FETCH_BASE_URL_FROM_SESSION_ERROR:
      return { ...state, baseURLError: true }
    default:
      return state
  }
}
