import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { DeviceState } from '../interfaces/device_state'

const initialState: DeviceState = {
  devices: [],
  devicesEmpty: false,
  count: 0,
  loading: false,
  deviceError: false,
}
export const deviceReducer = (
  state: DeviceState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionTypes.FETCHING_DEVICES:
      return { ...state, loading: true, devicesEmpty: [], deviceError: false }
    case ActionTypes.FETCH_DEVICES:
      return {
        ...state,
        count: payload.length,
        devices: payload,
        loading: false,
        devicesEmpty: payload.length === 0,
      }
    case ActionTypes.FETCH_DEVICES_ERROR:
      return { ...state, loading: false, deviceError: true }
    default:
      return state
  }
}
