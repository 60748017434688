import styled, { createGlobalStyle } from 'styled-components'
import ndsTokens from 'nicollet-tokens'
import { HeadingProps } from '../interfaces/heading_props'

export const GlobalStyles = createGlobalStyle`
  body {
    margin:0;
    color: ${ndsTokens.colors.palette.gray.darkest};
    font-family: ${ndsTokens.font.family.sansSerif};
  }
  html {
    width:100%;
  }
`
export const ContentViewport = styled.div`
  margin: 10px;
`

export const StyledGridContainer = styled.div`
  padding-top: 15px;
  width: 100%;
  max-width: 1128px;
  @media (min-width: ${ndsTokens.breakpoints.sm}) {
    margin: 15px auto 0px;
  }
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding-top: 0;
    margin-top: 0;
  }
  position: relative;
`
export const BrowseTypeGridContainer = styled.div`
  margin: 0px auto;
  width: 100%;
  max-width: 1200px;
`
export const HeadingContainer = styled.div<HeadingProps>`
  font-size: ${ndsTokens.font.size.heading.level1};
  margin-bottom: 12px;
`
export const SubHeadingContainer = styled.div<HeadingProps>`
  font-size: ${ndsTokens.font.size.text.medium};
  color: ${ndsTokens.colors.palette.gray.dark};
`
export const FooterContainer = styled.div`
  margin-top: auto;
`
export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
export const SpinnerWrapper = styled.div`
  height: calc(100vh - 250px);
  width: 100vw;
`
export const HeadingPropsDiv = styled.div<HeadingProps>``
