import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'

export const StyledSearchContainer = styled.div`
  width: 100%;
  max-width: 1128px;
  @media (min-width: ${ndsTokens.breakpoints.sm}) {
    margin: 15px auto 0px;
  }
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding-top: 0;
    margin-top: 0;
  }
  position: relative;
`
