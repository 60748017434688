import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'
import { HeadingProps } from '../../../common/interfaces/heading_props'

export const StyledImageContainer = styled.div<HeadingProps>`
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100%;
  align-items: center;
  text-align: center;
  align-items: center;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
`

export const StyledImg = styled.img<HeadingProps>`
  text-align: center;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  max-width: ${(props) => (props.width ? props.width : '70%;')};
  max-height: ${(props) => (props.height ? props.height : '200px;')};
  display: inline;
  padding-top: 15px;
`

export const RefinedSpan = styled.span<HeadingProps>`
  display: block;
  width: 90%;
  margin-left: 10px;
  grid-row: 2 / 3;
  text-align: center;
  font-size: ${ndsTokens.font.size.text.base};
  font-weight: 500;
  padding-bottom: 20px;
`
