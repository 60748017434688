import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'

export const TitleContainer = styled.div`
  font-weight: bold;
`

export const RefinedSpan = styled.span`
  margin-top: 400px;
`

export const PreviousStepContainer = styled.div`
  color: ${ndsTokens.colors.palette.gray.medium};
  margin-bottom: 20px;
  border-color: red;
  font-size: ${ndsTokens.font.size.text.medium};
  .leftIcon {
    height= 14px;
    width= 15px;
    fill = red;
    color: red;
  }
`
