import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'
import { Col, Row } from '@nicollet/react'

export const Bold = styled.span`
  font-weight: ${ndsTokens.font.weight.bold};
`
export const ResultDisplayRow = styled(Row)`
  padding: 25px;
  margin-bottom: 25px;
  background-color: ${ndsTokens.colors.background.lightest};
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding: 5px;
  }
`
export const ResultDisplayCol = styled(Col)`
  padding: 5px;
  height: 310px;
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    height: 260px;
  }
`
