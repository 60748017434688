import {
  ButtonPrimary,
  ButtonSecondary,
  Col,
  Grid,
  Row,
  Spinner,
} from '@nicollet/react'
import {
  BodyRow,
  HeaderRow,
  InfoGrid,
  OrderErrorContainer,
  PriceRow,
  RowLabel,
} from './styles'
import {
  HeadingContainer,
  HeadingPropsDiv,
  StyledGridContainer,
  SubHeadingContainer,
} from '../../../common/styles/global-styles'
import { Helmet } from 'react-helmet'
import { RootState } from '../../store/reducers'
import { useDispatch, useSelector } from 'react-redux'
import { QuoteResponse } from '../../interfaces/apiModels/response/quote/quote_response'
import { useEffect } from 'react'
import { fetchFinalQuoteFromSessionStorage } from '../../store/actions/deviceConditionsActions'
import {
  fetchOrderIdFromSessionStorage,
  postingOrder,
  postOrderRefresh,
} from '../../store/actions/orderActions'
import { OrderState } from '../../store/interfaces/order_state'
import { Paths } from '../../enums/paths'
import { useAnalytics } from '@praxis/component-analytics'
import { RegisteredTrademark } from '../../components/home/styles'
import { BaseURLState } from '../../store/interfaces/base_url_state'
import { ErrorPageNotFound } from '../../components/pageNotFound'

export const TradeComplete = () => {
  const { trackEvent } = useAnalytics()
  const dispatch = useDispatch()
  let orderId: string = useSelector((state: RootState) => {
    return state.order.orderId
  })
  let baseURLDetails: BaseURLState = useSelector((state: RootState) => {
    return state.baseURL
  })
  let { baseURLExist } = baseURLDetails

  useEffect(() => {
    dispatch(postingOrder())
  }, [])

  useEffect(() => {
    dispatch(fetchFinalQuoteFromSessionStorage())
    dispatch(fetchOrderIdFromSessionStorage())
  }, [])

  let quoteResponse: QuoteResponse = useSelector((state: RootState) => {
    return state.deviceConditions.quoteResponse
  })

  let orderState: OrderState = useSelector((state: RootState) => {
    return state.order
  })

  let { error, loading, orderErrorResponse, orderSubmitted } = orderState

  const analyticErrorProps = {
    event: {
      name: 'Order Submission Error',
      type: 'Error',
    },
    user: {},
  }

  useEffect(() => {
    if (error) {
      analyticErrorProps.user['customDimension1'] =
        orderErrorResponse?.message || 'No Provided Message (Generic UI Error)'
      trackEvent(analyticErrorProps)
    }
  }, [error])

  useEffect(() => {
    if (loading && !orderSubmitted && orderId) {
      dispatch(postOrderRefresh())
    }
  }, [loading, orderId, orderSubmitted])

  return (
    <Grid>
      <Helmet defaultTitle="Trade Complete" />
      {baseURLExist ? (
        <StyledGridContainer>
          {error && (
            <div>
              <HeadingContainer>Trade Submission Error</HeadingContainer>
              <OrderErrorContainer>
                {orderErrorResponse
                  ? orderErrorResponse.message
                  : 'Something went wrong, please try later'}
              </OrderErrorContainer>
            </div>
          )}

          {loading && <Spinner size="large" />}

          {!loading && !error && (
            <div>
              <HeadingContainer role="heading" aria-level="2">
                Trade Submission Complete
              </HeadingContainer>

              <SubHeadingContainer>
                Congratulations! Your trade submission is complete. You will be
                receiving an email outlining the next steps of your trade-in
                from Assurant<RegisteredTrademark>&reg;</RegisteredTrademark>{' '}
                shortly. If you do not see it in your inbox, please check your
                junk mail folder.
              </SubHeadingContainer>

              <Row>
                <Col lg={10} xs={12}>
                  <InfoGrid>
                    <HeaderRow>
                      <RowLabel role="heading" aria-level="3">
                        Trade Details
                      </RowLabel>
                    </HeaderRow>
                    <BodyRow>
                      <Col xs={3}>
                        <RowLabel role="heading" aria-level="3">
                          Invoice#:
                        </RowLabel>
                      </Col>
                      <Col xs={9}>{orderId}</Col>
                    </BodyRow>
                    <BodyRow>
                      <Col xs={3}>
                        <RowLabel role="heading" aria-level="3">
                          Date:
                        </RowLabel>
                      </Col>
                      <Col xs={9}>{new Date().toLocaleString()}</Col>
                    </BodyRow>
                    <BodyRow>
                      <Col xs={3}>
                        <RowLabel role="heading" aria-level="3">
                          Quantity:
                        </RowLabel>
                      </Col>
                      <Col xs={8}>1</Col>
                    </BodyRow>
                  </InfoGrid>
                  <PriceRow end={'xs'}>
                    <Col lg={6} xs={9}>
                      <HeadingPropsDiv role="heading" aria-level="2">
                        Estimated Device trade-in Value:
                      </HeadingPropsDiv>
                    </Col>
                    <Col lg={3} xs={3}>
                      ${quoteResponse.amount.toFixed(2)}
                    </Col>
                  </PriceRow>
                </Col>
              </Row>
            </div>
          )}

          <Row style={{ paddingTop: '30px' }} xsGutter="tight">
            <Col xs={5}>
              <ButtonSecondary isFullWidth href={Paths.TargetHome}>
                Return to Target.com
              </ButtonSecondary>
            </Col>
            <Col xs={5}>
              <ButtonPrimary isFullWidth href={Paths.Home}>
                Start a new trade
              </ButtonPrimary>
            </Col>
          </Row>
        </StyledGridContainer>
      ) : (
        <ErrorPageNotFound />
      )}
    </Grid>
  )
}
