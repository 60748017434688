import {
  BaseModalFloat,
  ButtonPrimary,
  ButtonSecondary,
  Col,
  Grid,
  Row,
} from '@nicollet/react'
import React from 'react'
import {
  HeadingContainer,
  StyledGridContainer,
  SubHeadingContainer,
} from '../../../common/styles/global-styles'
import { useCallback, useEffect, useState } from 'react'
import { STATE_NAMES } from '../../constants/state_names'
import { StatusCodes } from 'http-status-codes'
import {
  sanitizePhoneNumber,
  validateAddressLine1,
  validateIsEmail,
  validateIsName,
  validateIsPhoneNumber,
  validateIsZipCode,
  validateState,
} from '../../utils/forms/validation'
import { FormInput } from '../../utils/forms/form-input'
import {
  ButtonContainer,
  ModalStyle,
  PaddedRow,
  RefinedInput,
  RefinedInputPhoneNumber,
  RefinedSelect,
} from './styles'
import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet'
import { Paths } from '../../enums/paths'
import { useDispatch, useSelector } from 'react-redux'
import { saveCustomerToSessionStorage } from '../../store/actions/customerActions'
import { RootState } from '../../store/reducers'
import { TargetAddressDTO } from '../../interfaces/apiModels/thirdParty/target/getAddress/TargetAddressDTO'
import { Customer } from '../../interfaces/apiModels/response/order/customer'
import { useEnv } from '@praxis/component-runtime-env'
import { useAnalytics } from '@praxis/component-analytics'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AddressValidationRequestDTO } from '../../interfaces/apiModels/thirdParty/target/validateAddress/AddressValidationRequestDTO'
import { axiosInstance } from '../../apis/axiosInstance'
import { Environments } from '../../store/constants/environments'
import { GenericErrorContainer } from '../../components/styles'
import { AddressSuggestion } from '../../components/addressSuggestion'

export const PersonalInformation = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const env = useEnv<any>()

  const [pageError, setPageError] = useState<string>()
  const [suggestedAddresses, setSuggestedAddresses] = useState<any[]>([])

  const { trackEvent, trackClick } = useAnalytics()

  let savedCustomerAddress: Customer = useSelector((state: RootState) => {
    return state.customer.customer
  })

  let targetCustomerIdToken: any = useSelector((state: RootState) => {
    return state.customer.idTokenData
  })

  let targetCustomerAddress: TargetAddressDTO = useSelector(
    (state: RootState) => {
      return state.customer.targetCustomerAddress
    },
  )

  let loadedFirstName: string =
    savedCustomerAddress?.first_name || targetCustomerAddress?.first_name || ''
  const [firstName, setFirstName] = useState<FormInput>({
    value: loadedFirstName,
    isValid:
      loadedFirstName === '' ? undefined : validateIsName(loadedFirstName),
  })
  const [middleInitial, setMiddleInitial] = useState<FormInput>({
    value: '',
    isValid: true,
  })
  let loadedLastName =
    savedCustomerAddress?.last_name || targetCustomerAddress?.last_name || ''
  const [lastName, setLastName] = useState<FormInput>({
    value: loadedLastName,
    isValid: loadedLastName === '' ? undefined : validateIsName(loadedLastName),
  })
  let loadedAddressLine1 =
    savedCustomerAddress?.address_line_1 ||
    targetCustomerAddress?.address_line1 ||
    ''
  const [address1, setAddress1] = useState<FormInput>({
    value: loadedAddressLine1,
    isValid:
      loadedAddressLine1 === ''
        ? undefined
        : validateAddressLine1(loadedAddressLine1),
  })
  const [address2, setAddress2] = useState<FormInput>({
    value:
      savedCustomerAddress?.address_line_2 ||
      targetCustomerAddress?.address_line2 ||
      '',
    isValid: true,
  })
  let loadedCity =
    savedCustomerAddress?.city || targetCustomerAddress?.city || ''
  const [city, setCity] = useState<FormInput>({
    value: loadedCity,
    isValid: loadedCity === '' ? undefined : validateIsName(loadedCity),
  })
  let loadedState =
    savedCustomerAddress?.state || targetCustomerAddress?.state || ''
  const [state, setState] = useState<FormInput>({
    value: loadedState,
    isValid: loadedState === '' ? undefined : validateState(loadedState),
  })
  let loadedZipCode =
    savedCustomerAddress?.zip_code || targetCustomerAddress?.zip_code || ''
  const [zipCode, setZipCode] = useState<FormInput>({
    value: loadedZipCode,
    isValid:
      loadedZipCode === '' ? undefined : validateIsZipCode(loadedZipCode),
  })
  let loadedPhoneNumber =
    savedCustomerAddress?.phone || targetCustomerAddress?.phone_number || ''
  const [phoneNumber, setPhoneNumber] = useState<FormInput>({
    value: loadedPhoneNumber,
    isValid:
      loadedPhoneNumber === ''
        ? undefined
        : validateIsPhoneNumber(loadedPhoneNumber),
  })
  let loadedEmail =
    savedCustomerAddress?.email || targetCustomerIdToken?.pro?.em || ''
  const [email, setEmail] = useState<FormInput>({
    value: loadedEmail,
    isValid: loadedEmail === '' ? undefined : validateIsEmail(loadedEmail),
  })

  const [isSuggestionBoxOpen, setSuggestionBoxOpen] = useState(false)

  function getCustomer() {
    return {
      first_name: firstName.value,
      last_name: lastName.value,
      email: email.value,
      address_line_1: address1.value,
      address_line_2: address2.value,
      city: city.value,
      state: state.value,
      country: 'US',
      zip_code: zipCode.value,
      phone: phoneNumber.value,
    }
  }

  const suggestionBoxProps = {
    headingText: 'Suggested Addresses',
    isOpen: isSuggestionBoxOpen,
    onRequestClose: () => setSuggestionBoxOpen(false),
    iconButtonCloseOnClick: () => setSuggestionBoxOpen(false),
  }

  let makeAddressSelection = (selectedAddress: any, addressIndex: number) => {
    if (addressIndex === 0) {
      trackClick('User Entered Address')
    } else {
      trackClick('Suggested Address')
    }
    let customer = getCustomer()
    customer.address_line_1 = selectedAddress.address_line1
    customer.address_line_2 = selectedAddress.address_line2
    customer.city = selectedAddress.city
    customer.state = selectedAddress.state
    customer.zip_code = selectedAddress.zip_code
    proceed(customer)
  }
  const responseHasAddressSuggestion = (
    response: any,
    originalRequest: Customer,
  ): boolean => {
    const modifications = response?.avs_status?.modifications
    const successfulResponseZipCode = response?.address?.zip_code
    const errorResponseZipCode =
      response?.suggestions && response.suggestions.length > 0
        ? response.suggestions[0].address.zip_code
        : undefined

    const zipCodeChangeSuggested =
      response?.avs_status?.modifications?.post_code_corrected &&
      originalRequest.zip_code.substring(0, 5) !==
        (successfulResponseZipCode || errorResponseZipCode).substring(0, 5)

    return (
      (response?.suggestions && !zipCodeChangeSuggested) ||
      (modifications &&
        (modifications.bldg_or_firm_name_changed ||
          modifications.primary_number_changed ||
          modifications.street_corrected ||
          modifications.city_name_changed ||
          modifications.state_province_changed ||
          zipCodeChangeSuggested))
    )
  }

  const validateAddress = async (customer: Customer) => {
    let axiosRequest: AxiosRequestConfig
    if (env.environment === Environments.LOCAL) {
      axiosRequest = {
        baseURL: '.',
        url: '/mock_data/target/getAddressValidationResponse.json',
        responseType: 'json',
      }
    } else {
      const requestAddress: AddressValidationRequestDTO = {
        city: customer.city,
        state: customer.state,
        country: customer.country,
        zip_code: customer.zip_code,
        address_line1: customer.address_line_1,
        address_line2: customer.address_line_2,
        show_address_suggestions: false,
      }
      axiosRequest = {
        method: 'post',
        baseURL: env.target.guest_details_v1.baseUrl,
        url: '/guest_address_validations/v1/verify_addresses',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        responseType: 'json',
        data: requestAddress,
      }
    }

    // @ts-ignore
    let response: AxiosResponse = {}
    const analyticProps = {
      event: {
        name: 'address_suggestion',
        type: 'suggestion',
      },
      user: {},
    }
    try {
      response = await axiosInstance.request(axiosRequest)

      if (responseHasAddressSuggestion(response.data, customer)) {
        const addressSuggestions = [
          {
            address: {
              ...customer,
              address_line1: customer.address_line_1,
              address_line2: customer.address_line_2 || undefined,
            },
          },
          {
            address: response.data.address,
          },
        ]

        analyticProps.user['customDimension1'] = 'successful_response'
        trackEvent(analyticProps)

        //Show modal to pick appropriate address
        setSuggestedAddresses(addressSuggestions)
        setSuggestionBoxOpen(true)
      } else {
        return true
      }
    } catch (exception: any) {
      if (exception?.response?.status === StatusCodes.CONFLICT) {
        if (responseHasAddressSuggestion(exception.response.data, customer)) {
          exception.response.data.suggestions.unshift({
            address: {
              ...customer,
              address_line1: customer.address_line_1,
              address_line2: customer.address_line_2 || undefined,
            },
          })

          analyticProps.user['customDimension1'] = 'conflict_response'
          trackEvent(analyticProps)

          //Show modal to pick appropriate address
          setSuggestedAddresses(exception.response.data.suggestions)
          setSuggestionBoxOpen(true)
        } else {
          throw new Error(
            //TODO: log error to back end
            //Don't know what the intended address is
            'There appears to be an issue with this address, please verify that it is correct',
          )
        }
      } else if (exception?.response?.status === StatusCodes.BAD_REQUEST) {
        throw new Error(
          //TODO: log error to back end
          //Don't know what the intended address is
          'There appears to be an issue with this address, please verify that it is correct',
        )
      } else {
        return true
      }
    }
  }

  const verifyAddressAndProceed = () => {
    const customer: Customer = getCustomer()
    validateAddress(customer)
      .then((response) => {
        if (response) {
          proceed(customer)
        }
      })
      .catch((error) => {
        const analyticProps = {
          event: {
            name: 'address_suggestion',
            type: 'suggestion_error',
          },
          user: {
            customDimension1: customer.address_line_1,
            customDimension2: customer.address_line_2,
            customDimension3:
              customer.city + ', ' + customer.state + ', ' + customer.zip_code,
          },
        }
        trackEvent(analyticProps)
        setPageError(error.message)
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      })
  }

  const proceed = (customer: Customer) => {
    //Take the first 5 characters of zip code to not break Assurant
    customer.zip_code = customer.zip_code.substring(0, 5)
    dispatch(saveCustomerToSessionStorage(customer))
    navigate(Paths.Review)
  }

  const [isFormValid, setFormValid] = useState(false)

  const onChange = useCallback(
    (value: string, event: React.ChangeEvent<any>) => {
      const targetId: string = event?.target?.id
      switch (targetId) {
        case 'firstName': {
          const isValid: boolean = validateIsName(value)
          setFirstName({ value, isValid })
          break
        }
        case 'middleInitial': {
          setMiddleInitial({ value })
          break
        }
        case 'lastName': {
          const isValid: boolean = validateIsName(value)
          setLastName({ value, isValid })
          break
        }
        case 'address1': {
          const isValid = validateAddressLine1(value)
          setAddress1({ value, isValid })
          break
        }
        case 'address2': {
          setAddress2({ value })
          break
        }
        case 'city': {
          const isValid: boolean = validateIsName(value)
          setCity({ value, isValid })
          break
        }
        case 'state': {
          const isValid = validateState(value)
          setState({ value, isValid })
          break
        }
        case 'zipCode': {
          const isValid: boolean = validateIsZipCode(value)
          setZipCode({ value, isValid: isValid })
          break
        }
        case 'email': {
          const isValid: boolean = validateIsEmail(value)
          setEmail({ value, isValid: isValid })
          break
        }
        case 'phoneNumber': {
          setPhoneNumber({
            value: sanitizePhoneNumber(value),
            isValid: validateIsPhoneNumber(value),
          })
          break
        }
        default:
          break
      }
    },
    [],
  )

  useEffect(() => {
    let isResponseValid = Boolean(
      firstName?.isValid &&
        lastName?.isValid &&
        address1?.isValid &&
        city?.isValid &&
        state?.isValid &&
        zipCode?.isValid &&
        phoneNumber?.isValid &&
        email?.isValid,
    )
    setFormValid(isResponseValid)
  }, [firstName, address1, city, state, zipCode, phoneNumber, email, lastName])

  const handleStateDropDownChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value: string = e?.currentTarget?.value ?? ''
      const isValid = Boolean(value !== null)
      setState({ value, isValid })
    },
    [],
  )

  const handleChangeOnBlur = useCallback((value: any, id: string) => {
    if (value === '') {
      switch (id) {
        case 'firstName': {
          const isValid: boolean = validateIsName(value)
          setFirstName({ value, isValid })
          break
        }
        case 'middleInitial': {
          setMiddleInitial({ value })
          break
        }
        case 'lastName': {
          const isValid: boolean = validateIsName(value)
          setLastName({ value, isValid })
          break
        }
        case 'address1': {
          const isValid = validateAddressLine1(value)
          setAddress1({ value, isValid })
          break
        }
        case 'address2': {
          setAddress2({ value })
          break
        }
        case 'city': {
          const isValid: boolean = validateIsName(value)
          setCity({ value, isValid })
          break
        }
        case 'state': {
          const isValid = validateState(value)
          setState({ value, isValid })
          break
        }
        case 'zipCode': {
          const isValid: boolean = validateIsZipCode(value)
          setZipCode({ value, isValid: isValid })
          break
        }
        case 'email': {
          const isValid: boolean = validateIsEmail(value)
          setEmail({ value, isValid: isValid })
          break
        }
        case 'phoneNumber': {
          setPhoneNumber({
            value: sanitizePhoneNumber(value),
            isValid: validateIsPhoneNumber(value),
          })
          break
        }
        default:
          break
      }
    }
  }, [])

  return (
    <>
      <Helmet defaultTitle="Personal Information" />
      <form>
        <Grid>
          <StyledGridContainer>
            <HeadingContainer role="heading" aria-level="2">
              Personal Information
            </HeadingContainer>
            <SubHeadingContainer style={{ paddingBottom: '40px' }}>
              Please provide information about you so we can generate a postage
              label with your return address on it.
            </SubHeadingContainer>
            {pageError && (
              <GenericErrorContainer
                style={{ marginTop: '-20px', marginLeft: '0' }}
                className="errors"
                role="alert"
                aria-relevant="all"
                tabIndex={0}
              >
                {pageError}
              </GenericErrorContainer>
            )}
            <SubHeadingContainer style={{ paddingBottom: '10px' }}>
              * Required Fields
            </SubHeadingContainer>
            <Row>
              <Col lg={5} xs={12}>
                <RefinedInput
                  className="searchInput"
                  errorText="First name is not valid"
                  id="firstName"
                  isValid={firstName?.isValid}
                  label="* First Name"
                  onChange={onChange}
                  onBlur={(value) => handleChangeOnBlur(value, 'firstName')}
                  required={true}
                  value={firstName?.value}
                />
              </Col>
              <Col lg={2} xs={12}>
                <RefinedInput
                  className="searchInput"
                  id="middleInitial"
                  label="Middle Initial"
                  onChange={onChange}
                  onBlur={(value) => handleChangeOnBlur(value, 'middleInitial')}
                  value={middleInitial?.value}
                />
              </Col>
              <Col lg={5} xs={12}>
                <RefinedInput
                  className="searchInput"
                  errorText="Last name is not valid"
                  id="lastName"
                  isValid={lastName?.isValid}
                  label="* Last Name"
                  onChange={onChange}
                  onBlur={(value) => handleChangeOnBlur(value, 'lastName')}
                  required={true}
                  value={lastName?.value}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <RefinedInput
                  className="searchInput"
                  errorText="Enter address"
                  id="address1"
                  isValid={address1?.isValid}
                  label="* Address 1"
                  onChange={onChange}
                  onBlur={(value) => handleChangeOnBlur(value, 'address1')}
                  required={true}
                  value={address1?.value}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <RefinedInput
                  className="searchInput"
                  id="address2"
                  label="Address 2"
                  onChange={onChange}
                  onBlur={(value) => handleChangeOnBlur(value, 'address2')}
                  value={address2?.value}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={5} xs={12}>
                <RefinedInput
                  className="searchInput"
                  errorText="city is not valid"
                  id="city"
                  isValid={city?.isValid}
                  label="* City"
                  onChange={onChange}
                  onBlur={(value) => handleChangeOnBlur(value, 'city')}
                  required={true}
                  value={city?.value}
                />
              </Col>
              <Col lg={2} xs={6}>
                <RefinedSelect
                  className="select"
                  errorText="Select a state"
                  id="state"
                  isValid={state?.isValid}
                  label="* State"
                  onChange={handleStateDropDownChange}
                  onBlur={() => handleChangeOnBlur(state?.value, 'state')}
                  options={STATE_NAMES}
                  required={true}
                  value={state?.value}
                  aria-selected={true}
                />
              </Col>
              <Col lg={5} xs={12}>
                <RefinedInput
                  className="searchInput"
                  errorText="zipCode is not valid"
                  id="zipCode"
                  isValid={zipCode?.isValid}
                  label="* Zip code"
                  onChange={onChange}
                  onBlur={(value) => handleChangeOnBlur(value, 'zipCode')}
                  required={true}
                  value={zipCode?.value}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={12}>
                <RefinedInputPhoneNumber
                  className="searchInput"
                  errorText="phone number is not valid"
                  id="phoneNumber"
                  isValid={phoneNumber?.isValid}
                  label="* Phone number"
                  onChange={onChange}
                  onBlur={(value) => handleChangeOnBlur(value, 'phoneNumber')}
                  required={true}
                  value={phoneNumber?.value}
                />
              </Col>
              <Col lg={6} xs={12}>
                <RefinedInput
                  className="searchInput"
                  errorText="email is not valid"
                  id="email"
                  isValid={email?.isValid}
                  label="* Email address"
                  onChange={onChange}
                  onBlur={(value) => handleChangeOnBlur(value, 'email')}
                  required={true}
                  value={email?.value}
                />
              </Col>
            </Row>
            <Row xsGutter="tight">
              <Col xs={6}>
                <ButtonContainer>
                  <ButtonSecondary isFullWidth href={Paths.Home}>
                    Cancel
                  </ButtonSecondary>
                </ButtonContainer>
              </Col>
              <Col xs={6}>
                <ButtonContainer>
                  <ButtonPrimary
                    disabled={!isFormValid}
                    isFullWidth
                    onClick={() => verifyAddressAndProceed()}
                  >
                    Continue
                  </ButtonPrimary>
                </ButtonContainer>
              </Col>
            </Row>
          </StyledGridContainer>
        </Grid>
      </form>
      <ModalStyle />
      <BaseModalFloat {...suggestionBoxProps}>
        <PaddedRow>
          It looks like there are alternate suggestions for that address, please
          pick the most correct one:
        </PaddedRow>
        <Row style={{ height: suggestedAddresses.length * 96 + 'px' }}>
          {suggestedAddresses.map((address, index) => {
            return (
              <AddressSuggestion
                key={index}
                index={index}
                addressSuggestion={address}
                addressSelectionCallback={makeAddressSelection}
              />
            )
          })}
        </Row>
      </BaseModalFloat>
    </>
  )
}
