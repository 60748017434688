import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'
import { Input, Radio, InputPhoneNumber } from '@nicollet/react'

export const StyledGridContainer = styled.div`
  margin: 10px auto;
  width: 100%;
  max-width: 1128px;
  font-size: 12px;
  @media (min-width: ${ndsTokens.breakpoints.sm}) {
    font-size: 14px;
    padding: 15px 20px;
  }
`

export const RefinedInput = styled(Input)`
  padding-bottom: 30px;
  @media (min-width: ${ndsTokens.breakpoints.lg}) {
    padding-right: 60px;
  }
  label {
    color: ${ndsTokens.colors.palette.gray.medium};
  }
  .searchInput {
    border: 3px solid ${ndsTokens.colors.palette.gray.light};
  }
`

export const QuestionContainer = styled.div`
  @media (min-width: ${ndsTokens.breakpoints.sm}) {
    padding-right: 60px;
  }
  font-size: ${ndsTokens.font.size.text.medium};
  padding-bottom: 10px;
`

export const DeviceContainer = styled.div`
  margin-bottom: 20px;
  @media (min-width: ${ndsTokens.breakpoints.lg}) {
    border-left: 1px solid ${ndsTokens.colors.palette.gray.light};
  }
`

export const StyledRadio = styled(Radio)`
  margin-bottom: 30px;
  margin-right: 10px;
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding-left: 20px;
    margin-bottom: 20px;
  }
`

export const SubHeadingContainer = styled.div`
  padding-bottom: 40px;
  color: ${ndsTokens.colors.palette.gray.medium};
`

export const PriceContainer = styled.div`
  font-size: ${ndsTokens.font.size.hero.level3};
  font-weight: bold;
  margin-top: 10px;
`

export const RefinedButton = styled.div`
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: normal;
`

export const RefinedInputPhoneNumber = styled(InputPhoneNumber)`
  padding-bottom: 30px;
  @media (min-width: ${ndsTokens.breakpoints.lg}) {
    padding-right: 60px;
  }
  label {
    color: ${ndsTokens.colors.palette.gray.medium};
  }
  .searchInput {
    border: 3px solid ${ndsTokens.colors.palette.gray.light};
  }
`

export const PaddedLabel = styled.div`
  margin-bottom: 20px;
`
export const BodyMargin = styled.div`
  margin-top: 50px;
`
export const QuestionErrorContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  align-items: center;
  text-align: center;
  color: ${ndsTokens.colors.palette.red.default};
  font-size: ${ndsTokens.font.size.text.large};
`
export const QuoteErrorContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  align-items: center;
  text-align: center;
  color: ${ndsTokens.colors.palette.red.default};
  font-size: ${ndsTokens.font.size.text.large};
`
