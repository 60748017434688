/*

👋 “Hey” from the Praxis team!

'apiConfig' is one pattern that can be used to set environment-based values at build time.
This happens by creating an environment variable REACT_APP_ENV, and then matching corresponding
keys to each environment in the 'envConfigs' object below. To learn more about how to set
REACT_APP_ENV, view the README generated with this app.


For an alternative pattern - using TAP configuration to set environment-based values at cluster initialization - see:
@praxis/component-runtime-env - https://praxis.prod.target.com/components/runtime-env

*/

import { getEnvConfig } from '@praxis/component-runtime-env'

const config = async () => {
  const envConfig = await getEnvConfig(
    process.env.NODE_ENV === 'development'
      ? '/config.json'
      : '/app-environment',
  )
  return envConfig
}

export default config
