import store from '../store/configureStore'
import {
  fetchCustomerFromSessionStorage,
  fetchIdToken,
  fetchLoggedInCustomerData,
} from '../store/actions/customerActions'
import { AxiosRequestHeaders, AxiosRequestConfig } from 'axios'
import {
  TEST_HEADER,
  TEST_CLIENT_ID,
  TEST_OWNER_ID,
  TEST_OWNER_SECRET,
} from '../constants/common-constants'
import { initializeAxiosInstance } from '../apis/axiosInstance'
import { TokenRequest } from '../interfaces/apiModels/response/token/token_request'
import { functionToRetrieveFromConfigFile } from '../../common/apis/storeApi'
import axios from 'axios'

export const InitializeData = async (env) => {
  await initializeAxios(env)
  store.dispatch(fetchCustomerFromSessionStorage())
  store.dispatch(fetchLoggedInCustomerData(env))
  store.dispatch(fetchIdToken(env))
}

const initializeAxios = async (env) => {
  let headers: AxiosRequestHeaders = {
    'x-api-key': env.api_key,
  }
  if (process.env.REACT_APP_TEST === 'e2e') {
    let response = await functionToRetrieveFromConfigFile()
    let gspAuthHost = response.gsp.url
    let authResponse = await getAuthToken(gspAuthHost)
    headers = {
      'x-api-key': env.api_key,
      c_tgt: TEST_HEADER,
      Authorization: 'Bearer ' + authResponse?.data.access_token,
    }
  }
  const config: AxiosRequestConfig = {
    withCredentials: true,
    headers: headers,
  }
  await initializeAxiosInstance(config)
}

const getAuthToken = async (gspAuthHost: string) => {
  try {
    let tokenRequest: TokenRequest = {
      client_credential: { client_id: TEST_CLIENT_ID },
      resource_owner: {
        owner_id: TEST_OWNER_ID,
        owner_secret: TEST_OWNER_SECRET,
      },
      assurance_level: 'MEDIUM',
      grant_type: 'password',
    }
    return await axios.post(
      `${gspAuthHost}/gsp/oauth_tokens/v2/client_tokens`,
      tokenRequest,
    )
  } catch (error: any) {
    console.log('error retrieving auth token')
  }
  return null
}
