import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'
import { Link } from '@nicollet/react'
import { HeadingProps } from '../../../common/interfaces/heading_props'

export const StyledLink = styled(Link)<HeadingProps>`
  font-size: ${ndsTokens.font.size.heading.level4};
  font-weight: ${ndsTokens.font.weight.bold};
  color: ${ndsTokens.colors.palette.white} !important;
  left: 20px;
  @media (min-width: ${ndsTokens.breakpoints.sm}) {
    left: 40px;
  }
  &:focus {
    outline: 1px dashed ${ndsTokens.colors.palette.white};
  }
`
