import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { ManufacturerState } from '../interfaces/manufacturer_state'

const initialState: ManufacturerState = {
  manufacturers: [],
  manufacturersEmpty: false,
  count: 0,
  loading: false,
  manufacturerError: false,
}
export const manufacturerReducer = (
  state: ManufacturerState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionTypes.FETCHING_MANUFACTURERS:
      return {
        ...state,
        loading: true,
        manufacturers: [],
        manufacturerError: false,
      }
    case ActionTypes.FETCH_MANUFACTURERS:
      return {
        ...state,
        count: payload.length,
        manufacturers: payload,
        loading: false,
        manufacturersEmpty: payload.length === 0 ? true : false,
      }
    case ActionTypes.FETCH_MANUFACTURERS_ERROR:
      return { ...state, loading: false, manufacturerError: true }
    default:
      return state
  }
}
