import styled from 'styled-components'
import { Input, ButtonPrimary } from '@nicollet/react'
import ndsTokens from 'nicollet-tokens'
import { HeadingProps } from '../../../common/interfaces/heading_props'

export const RefinedInput = styled(Input)`
  padding-top: 20px;
  padding-bottom: 30px;
  width: 100%;
  label {
    top: 32px;
    width: 80%;
    color: ${ndsTokens.colors.palette.gray.medium};
  }
  .searchInput {
    border: 3px solid ${ndsTokens.colors.palette.gray.light};
    height: 70px;
    padding-right: 33px;
  }
  .hfoLHR {
    font-size: ${ndsTokens.font.size.text.large};
  }
`

export const StyledSearchContainer = styled.div`
  position: relative;
`
export const StyledSearchDeviceContainer = styled.div`
  margin-top: 10px;
`

export const RefinedButton = styled(ButtonPrimary)`
  background-color: transparent;
  position: absolute;
  top: 35px;
  right: 0;
  border-color: transparent;
  &:hover {
    border-color: transparent;
    background-color: transparent;
  }
`

export const DeviceContainer = styled.div<HeadingProps>`
  padding-bottom: 40px;
  font-size: ${ndsTokens.font.size.heading.level2};
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding-bottom: 5px;
  }
`
export const ErrorContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  align-items: center;
  text-align: center;
  color: ${ndsTokens.colors.palette.gray.medium};
  font-size: ${ndsTokens.font.size.text.large};
`
