import { RefinedSpan, StyledImageContainer, StyledImg } from './styles'
import { LabeledImageParameters } from './labeled-image-parameters'

/**
 *
 * @param imageUrl - Where image is loaded from
 * @param label - Text that goes under the image
 * @param altText - Screen Reader text
 * @param callback - Function to call on click
 * @param callbackValue - What to pass into callback on click by default it's the label
 * @constructor
 */
export const LabeledImage: React.FC<any> = ({
  imageUrl,
  label,
  altText,
  callBack,
  imageWidth,
  page,
}: LabeledImageParameters) => {
  let parameters: LooseObject = { alt: altText, src: imageUrl }

  if (imageWidth) {
    parameters.width = imageWidth
  }

  const getLabelValue = (page: string, label: string) => {
    return page === 'DeviceConditionsPage' || page === 'ReviewPage'
      ? label
      : label + ' link'
  }

  return (
    <StyledImageContainer
      tabIndex={0}
      aria-label={getLabelValue(page, label)}
      onKeyPress={parameters.onClick}
      onClick={callBack}
    >
      <div>
        <StyledImg aria-disabled={true} {...parameters} />
      </div>
      <RefinedSpan aria-disabled={true} id={'image_label'}>
        {label}
      </RefinedSpan>
    </StyledImageContainer>
  )
}
