import styled from 'styled-components'

export const BreadCrumbContainer = styled.div`
  margin-top: 55px;
  margin-bottom: 20px;
`

export const MobileNavigationContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`
