import React from 'react'
import { Breadcrumb } from '@nicollet/react'
import { Link as NextLink } from 'react-router-dom'
import { BreadCrumbModel } from '../../components/browseCategoryLayout/breadcrumb-model'
import {
  PageSelection,
  RefinedSpan,
  LinkRefinedSpan,
  SelectedValueContainer,
} from './styles'
import { BreadcrumbProperties } from './breadcrumb-properties'

export const BreadcrumbNavigation: React.FC<BreadcrumbProperties> = ({
  breadcrumbFixture,
  pathname,
}) => {
  const pathnames: string[] = (pathname || '')?.split('/').filter((x) => x)
  let noCapacityIndex: number = pathnames.indexOf('0')
  let lastIndex = 0

  breadcrumbFixture.forEach((element, index) => {
    if (
      element.component ===
      (Array.isArray(pathnames) && pathnames[pathnames.length - 1])
    ) {
      lastIndex = index
    }
  })

  const slicedBreadcrumb: BreadCrumbModel[] = breadcrumbFixture.slice(
    0,
    lastIndex + 1,
  )
  // remove capacity if no capacitites found, capacity selected is X in url
  if (noCapacityIndex > 0) {
    slicedBreadcrumb.splice(3, 1)
    lastIndex--
  }

  return (
    <PageSelection>
      <div className="h-margin-h-default">
        <Breadcrumb>
          {slicedBreadcrumb.map((breadcrumb, i) => {
            const routeTo = `/${pathnames
              .slice(0, pathnames.indexOf(breadcrumb.component) + 1)
              .join('/')}`

            if (i < lastIndex) {
              const selectedValue: string = decodeURIComponent(
                pathnames[pathnames.indexOf(breadcrumb.component) + 1],
              )
              return (
                <NextLink key={i} to={routeTo}>
                  <SelectedValueContainer>
                    <LinkRefinedSpan>{selectedValue}</LinkRefinedSpan>
                  </SelectedValueContainer>
                </NextLink>
              )
            }
            return (
              <RefinedSpan tabIndex={0} key={i}>
                {breadcrumb.title}
              </RefinedSpan>
            )
          })}
        </Breadcrumb>
      </div>
    </PageSelection>
  )
}

BreadcrumbNavigation.displayName = 'BreadcrumbNavigation '

export default BreadcrumbNavigation
