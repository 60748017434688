import { TradeInProvider } from '../../enums/tradein_provider'
import { ActionTypes } from '../constants/action-types'
import { Params } from '../../interfaces/params'
import { Action } from '../interfaces/action'
import { QuoteRequestAnswer } from '../../interfaces/apiModels/response/quote/quote_request_answer'
import { QuoteRequest } from '../../interfaces/apiModels/response/quote/quote_request'
import store from '../configureStore'
import { Storagekey } from '../constants/storage-keys'
import { sessionStorage } from '@web/storage'
import { QuoteResponse } from '../../interfaces/apiModels/response/quote/quote_response'
import { axiosInstance } from '../../apis/axiosInstance'
import { handleException } from '../../utils/error-handler'

export const fetchingQuestions = () => {
  return {
    type: ActionTypes.FETCHING_DEVICE_QUESTIONS,
  }
}

export const fetchQuestions = (baseURL: string, productId: string) => {
  return async (dispatch: any) => {
    try {
      const params: Params = {
        tradein_provider: TradeInProvider.Assurant,
        tradein_provider_product_id: productId,
      }
      const response = await axiosInstance.get(`${baseURL}/questions?`, {
        params: params,
        //headers: {'c_tgt': 'synth_IZLPRo79Xp'}, // hard code test header for now to test scenarios that need wiremock stubs
      })
      let action: Action = {
        type: ActionTypes.FETCH_DEVICE_QUESTIONS,
        payload: response.data,
      }
      dispatch(action)
    } catch (error: any) {
      handleException(error.response)
      let errorAction: Action = {
        type: ActionTypes.FETCH_DEVICE_QUESTIONS_ERROR,
        payload: error.response?.data,
      }
      dispatch(errorAction)
    }
  }
}

export const updateQuoteRequestAnswer = (answer: QuoteRequestAnswer) => {
  return {
    type: ActionTypes.UPDATE_QUOTE_REQUEST_ANSWER,
    payload: answer,
  }
}

export const postQuoteRefresh = () => {
  return {
    type: ActionTypes.POST_QUOTE_REFRESH,
  }
}

export const postQuoteLoad = () => {
  return {
    type: ActionTypes.POST_QUOTE_LOAD,
  }
}

export const postQuote = (
  baseURL: string,
  productId: string,
  isFinalQuote: boolean,
) => {
  return async (dispatch: any) => {
    try {
      let payload: QuoteRequest = {
        tradein_provider: TradeInProvider.Assurant,
        tradein_provider_product_id: productId,
        is_final_quote: isFinalQuote,
        answers: store.getState().deviceConditions.quoteRequestAnswers,
      }
      const response = await axiosInstance.post(`${baseURL}/quotes?`, payload, {
        //headers: {'c_tgt': 'synth_IZLPRo79Xp'}, // hard code test header for now to test scenarios that need wiremock stubs
      })
      if (isFinalQuote) dispatch(saveFinalQuoteToSessionStorage(response.data))
      let action: Action = {
        type: ActionTypes.POST_QUOTE,
        payload: response.data,
      }
      dispatch(action)
    } catch (error: any) {
      handleException(error.response)
      let errorAction: Action = {
        payload: error.response?.data,
        type: ActionTypes.POST_QUOTE_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const saveDeviceConditionsResponseToSessionStorage = (
  quoteRequestAnswer: QuoteRequestAnswer[],
) => {
  return async (dispatch: any) => {
    try {
      await sessionStorage.set(
        Storagekey.DEVICE_CONDITIONS_RESPONSE,
        quoteRequestAnswer,
      )
      let action: Action = {
        type: ActionTypes.SAVE_DEVICE_CONDITIONS_RESPONSE_TO_SESSION,
        payload: quoteRequestAnswer,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.SAVE_DEVICE_CONDITIONS_RESPONSE_TO_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const fetchDeviceConditionsResponseFromSessionStorage = () => {
  return async (dispatch: any) => {
    try {
      const value = await sessionStorage.get(
        Storagekey.DEVICE_CONDITIONS_RESPONSE,
      )
      let action: Action = {
        type: ActionTypes.FETCH_DEVICE_CONDITIONS_RESPONSE_FROM_SESSION,
        payload: value,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.FETCH_DEVICE_CONDITIONS_RESPONSE_FROM_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const saveDeviceNumberToSessionStorage = (deviceNumber: string) => {
  return async (dispatch: any) => {
    try {
      await sessionStorage.set(Storagekey.DEVICE_NUMBER, deviceNumber)
      let action: Action = {
        type: ActionTypes.SAVE_DEVICE_NUMBER_TO_SESSION,
        payload: deviceNumber,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.SAVE_DEVICE_NUMBER_TO_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const fetchDeviceNumberFromSessionStorage = () => {
  return async (dispatch: any) => {
    try {
      const value = await sessionStorage.get(Storagekey.DEVICE_NUMBER)
      let action: Action = {
        type: ActionTypes.FETCH_DEVICE_NUMBER_FROM_SESSION,
        payload: value,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.FETCH_DEVICE_NUMBER_FROM_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const saveFinalQuoteToSessionStorage = (
  quoteResponse: QuoteResponse,
) => {
  return async (dispatch: any) => {
    try {
      await sessionStorage.set(Storagekey.QUOTE_RESPONSE, quoteResponse)
      let action: Action = {
        type: ActionTypes.SAVE_PRICE_RESPONSE_TO_SESSION,
        payload: quoteResponse,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.SAVE_PRICE_RESPONSE_TO_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const fetchFinalQuoteFromSessionStorage = () => {
  return async (dispatch: any) => {
    try {
      const value = await sessionStorage.get(Storagekey.QUOTE_RESPONSE)
      let action: Action = {
        type: ActionTypes.FETCH_PRICE_RESPONSE_FROM_SESSION,
        payload: value,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.FETCH_PRICE_RESPONSE_FROM_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}
