import { HeaderLogo, HeaderPrimary } from '@nicollet/react'
import { Paths } from '../../enums/paths'
import { StyledLink } from './styles'

export const Header = () => {
  return (
    <HeaderPrimary>
      <HeaderLogo href={Paths.TargetHome} />
      <StyledLink
        role="heading"
        aria-level="1"
        href={Paths.Home}
        noHoverColor
        underline="never"
      >
        Trade-in Home
      </StyledLink>
    </HeaderPrimary>
  )
}
