import styled, { createGlobalStyle } from 'styled-components'
import ndsTokens from 'nicollet-tokens'
import { Col, Row } from '@nicollet/react'

export const PaddedText = styled.p`
  text-align: center;
  margin-bottom: 5px;
`

export const PriceRow = styled(Row)`
  font-size: ${ndsTokens.font.size.text.xlarge};
  font-weight: ${ndsTokens.font.weight.bold};
  margin-bottom: 20px;
  margin-top: 45px;
`

export const PriceCol = styled(Col)`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`
export const ModalStyle = createGlobalStyle`
.ReactModal__Content {
  max-width: 1100px !important;
  height: 100% !important;
      @media (max-width: ${ndsTokens.breakpoints.sm}) {
        max-width: 100% !important;
    }
  }
`

export const LowerLeftButton = styled(Col)`
  padding-right: 10px;
  margin-bottom: 40px;
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding: 5px;
  }
`

export const LowerRightButton = styled(Col)`
  padding-left: 10px;
  margin-bottom: 40px;
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding: 5px;
    margin-bottom: 0px;
  }
`

export const AcceptTcRow = styled(Row)`
  padding-bottom: 25px;
  line-height: 30px;
`
