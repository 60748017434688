import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { OrderRequest } from '../../interfaces/apiModels/response/order/order_request'
import { Storagekey } from '../constants/storage-keys'
import { sessionStorage } from '@web/storage'
import { axiosInstance } from '../../apis/axiosInstance'
import { handleException } from '../../utils/error-handler'

export const postingOrder = () => {
  return {
    type: ActionTypes.POSTING_ORDER,
  }
}

export const postOrderRefresh = () => {
  return {
    type: ActionTypes.POST_ORDER_REFRESH,
  }
}

export const postOrder = (baseURL: string, orderRequest: OrderRequest) => {
  return async (dispatch: any) => {
    try {
      const response = await axiosInstance.post(
        `${baseURL}/orders`,
        orderRequest,
        //{headers: {'c_tgt': 'synth_IZLPRo79Xp'}}, // hard coded for now to test error cases that need wiremock stubs.
      )
      let action: Action = {
        type: ActionTypes.POST_ORDER,
        payload: response.data,
      }
      dispatch(saveOrderIdToSessionStorage(response.data.order_id))
      dispatch(action)
    } catch (error: any) {
      handleException(error.response)
      let errorAction: Action = {
        type: ActionTypes.POST_ORDER_ERROR,
        payload: error.response?.data,
      }
      dispatch(errorAction)
    }
  }
}

export const saveOrderIdToSessionStorage = (orderId: string) => {
  return async (dispatch: any) => {
    try {
      await sessionStorage.set(Storagekey.ORDER_ID, orderId)
      let action: Action = {
        type: ActionTypes.SAVE_ORDER_ID_TO_SESSION,
        payload: orderId,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.SAVE_ORDER_ID_TO_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const fetchOrderIdFromSessionStorage = () => {
  return async (dispatch: any) => {
    try {
      const value = await sessionStorage.get(Storagekey.ORDER_ID)
      let action: Action = {
        type: ActionTypes.FETCH_ORDER_ID_FROM_SESSION,
        payload: value,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.FETCH_ORDER_ID_FROM_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}
