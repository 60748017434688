import { Product } from '../../interfaces/apiModels/response/products/product'
import { ActionTypes } from '../constants/action-types'
import { Storagekey } from '../constants/storage-keys'
import { Action } from '../interfaces/action'
import { sessionStorage } from '@web/storage'

export const saveProductToSessionStorage = (product: Product) => {
  return async (dispatch: any) => {
    try {
      await sessionStorage.set(Storagekey.PRODUCT_SELECTED, product)
      let action: Action = {
        type: ActionTypes.SAVE_PRODUCT_TO_SESSION,
        payload: product,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.SAVE_PRODUCT_TO_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const fetchProductFromSessionStorage = () => {
  return async (dispatch: any) => {
    try {
      const value = await sessionStorage.get(Storagekey.PRODUCT_SELECTED)
      let action: Action = {
        type: ActionTypes.FETCH_PRODUCT_FROM_SESSION,
        payload: value,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.FETCH_PRODUCT_FROM_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}
