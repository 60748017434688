import { useEffect, useState } from 'react'
import { IconGeneralSearch } from 'nicollet-icons-react'
import { Col, Grid, Row, Spinner } from '@nicollet/react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  DeviceContainer,
  ErrorContainer,
  RefinedButton,
  RefinedInput,
  StyledSearchContainer,
  StyledSearchDeviceContainer,
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { useAnalytics } from '@praxis/component-analytics'
import {
  fetchingPopularModels,
  fetchPopularModels,
} from '../../store/actions/popularModelActions'
import { FilterType } from '../../interfaces/filter_type'
import { RootState } from '../../store/reducers'
import { Filters } from '../../interfaces/filters'
import { Paths } from '../../enums/paths'
import { ModelState } from '../../store/interfaces/model_state'
import { POPULAR_PRODUCTS_DISPLAY_LIMIT } from '../../constants/index'
import { saveProductToSessionStorage } from '../../store/actions/productSelectedStorageActions'
import { Product } from '../../interfaces/apiModels/response/products/product'
import { SearchResults } from '../searchResults'
import { BaseURLState } from '../../store/interfaces/base_url_state'
import {
  fetchingModels,
  fetchModels,
  saveModelsToSessionStorage,
} from '../../store/actions/modelActions'
import { PER_PAGE_RESULTS } from '../../constants/common-constants'

export const SearchDevice = () => {
  let navigate = useNavigate()
  let location = useLocation()
  const dispatch = useDispatch()
  const [text, setText]: [string, (text: string) => void] = useState('')
  const [searchErrorMessage, setSearchErrorMessage]: [
    string,
    (text: string) => void,
  ] = useState('')
  const { trackClick } = useAnalytics()

  const handleChange = (value: string) => {
    setText(value)
  }

  const routeToResults = () => {
    if (text) {
      trackClick('Custom_Search_Term', text)
      let filters: Filters = {
        filters: [{ name: FilterType.Keyword, value: text }],
      }
      dispatch(fetchModels(baseURL, 1, PER_PAGE_RESULTS, filters))
    }
  }

  const routeToDeviceConditions = (product: Product) => {
    dispatch(saveProductToSessionStorage(product))
    navigate(
      Paths.DeviceConditions + '?id=' + product.tradein_provider_product_id,
    )
  }

  const handleSearchInputKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      routeToResults()
    }
  }

  let baseURLDetails: BaseURLState = useSelector((state: RootState) => {
    return state.baseURL
  })
  let { baseURL, baseURLExist } = baseURLDetails

  useEffect(() => {
    dispatch(fetchingModels())
    if (
      baseURLExist &&
      baseURL &&
      location?.pathname.indexOf('/search') === 0
    ) {
      dispatch(fetchingPopularModels())
      let filters: Filters = {
        filters: [{ name: FilterType.Popular_Products, value: true }],
      }
      dispatch(
        fetchPopularModels(baseURL, filters, POPULAR_PRODUCTS_DISPLAY_LIMIT),
      )
    }
  }, [baseURL, baseURLExist])

  let popularModels: ModelState = useSelector((state: RootState) => {
    return state.popularModels
  })

  let { models, loading, modelError, popularModelErrorResponse } = popularModels

  let keywordSearchModelState: ModelState = useSelector((state: RootState) => {
    return state.models
  })

  let keywordSearchModelsError = keywordSearchModelState.modelError
  let loadNext = keywordSearchModelState.loadNext
  let keywordSearchModels = keywordSearchModelState.models

  let { count, modelsEmpty } = keywordSearchModelState

  useEffect(() => {
    if (loadNext) {
      dispatch(
        saveModelsToSessionStorage({ count, products: keywordSearchModels }),
      )
      navigate(Paths.SearchResults + '?value=' + text)
    }
  }, [loadNext, text])

  useEffect(() => {
    if (keywordSearchModelsError && modelsEmpty) {
      setSearchErrorMessage('Sorry device not found, please search again')
    } else if (keywordSearchModelsError) {
      setSearchErrorMessage(
        'Something went wrong, please use browse by category or try again later',
      )
    }
  }, [keywordSearchModelsError, modelsEmpty])

  return (
    <>
      <Grid>
        <StyledSearchDeviceContainer>
          <Row>
            <Col lg={12} xs={12}>
              <StyledSearchContainer>
                <RefinedInput
                  className="searchInput"
                  errorText={searchErrorMessage}
                  isValid={!keywordSearchModelsError}
                  label="For best results include model, memory size, and color (ex. iPhone 64GB Black)"
                  onChange={handleChange}
                  onKeyDown={handleSearchInputKeyDown}
                  value={text}
                />
                <RefinedButton onClick={routeToResults}>
                  <IconGeneralSearch screenReaderText="Search" />
                </RefinedButton>
              </StyledSearchContainer>
            </Col>
          </Row>
          <Row>
            <DeviceContainer role="heading" aria-level="3">
              Popular Devices
            </DeviceContainer>
          </Row>
          {loading && <Spinner size="large" />}
          {!modelError && models.length > 0 && (
            <div>
              <SearchResults
                product_results={models}
                callBack={routeToDeviceConditions}
                loading={loading}
                searchLabel={'Popular_Devices'}
              />
            </div>
          )}
          {modelError && (
            <ErrorContainer>
              {popularModelErrorResponse && popularModelErrorResponse.message
                ? popularModelErrorResponse.message
                : 'Something went wrong, please check back later for popular devices'}
            </ErrorContainer>
          )}
        </StyledSearchDeviceContainer>
      </Grid>
    </>
  )
}
