import { QuestionType } from '../../enums/question_type'
import { Question } from '../../interfaces/apiModels/response/questions/question'
import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { DeviceConditionsState } from '../interfaces/device_conditions_state'
import { AnswerCode } from '../../enums/answer_code'
import { AnswerText } from '../../enums/answer_text'
import { TradeInProvider } from '../../enums/tradein_provider'
import { CurrencyType } from '../../enums/currency_type'

const initialState: DeviceConditionsState = {
  deviceQuestions: [],
  singleSelectQuestions: [],
  textQuestions: [],
  imeiQuestion: {
    question_id: '',
    question_type: QuestionType.Text,
    text: '',
    help_text: '',
    required: false,
  },
  imeiQuestionNotFound: true,
  phoneQuestion: {
    question_id: '',
    question_type: QuestionType.Text,
    text: '',
    help_text: '',
    required: false,
  },
  loading: false,

  questionsEmpty: false,
  questionsError: false,
  questionsErrorResponse: {
    code: '',
    message: '',
  },

  quoteErrorResponse: {
    code: '',
    message: '',
  },

  requiredQuestionsLength: 0,
  quoteRequestAnswers: [],
  deviceConditionsResponse: [],
  deviceNumber: '',
  quoteResponse: {
    tradein_provider: TradeInProvider.Assurant,
    tradein_provider_product_id: '',
    quote_id: '',
    amount: 0,
    currency: CurrencyType.USD,
  },

  quoteError: false,
  imeiValidated: false,
  conditionsResponseError: false,
  deviceNumberError: false,
  finalQuoteError: false,
  deviceValidationResponse: {
    code: '',
    message: '',
  },
}
export const deviceConditionsReducer = (
  state: DeviceConditionsState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionTypes.FETCHING_DEVICE_QUESTIONS:
      return {
        ...state,
        loading: true,
        deviceQuestions: [],
        singleSelectQuestions: [],
        textQuestions: [],
        quoteRequestAnswers: [],
        questionsLength: 0,
        requiredQuestionsLength: 0,
      }
    case ActionTypes.FETCH_DEVICE_QUESTIONS: {
      let questions: Question[] = payload.questions
      if (questions.length === 0) {
        return {
          ...state,
          deviceQuestions: questions,
          loading: false,
          questionsEmpty: true,
        }
      } else {
        questions.forEach((question) => {
          if (question.required) {
            if (question.question_type === QuestionType.Single_Select) {
              state.singleSelectQuestions.push(question)
              // saving default answer 'yes' for single select questions
              state.quoteRequestAnswers.push({
                question_id: question.question_id,
                answer_text: AnswerText.Yes,
                answer_code: AnswerCode.Y,
                question_type: question.question_type,
                question_text: question.text,
              })
              state.requiredQuestionsLength++
            } else if (question.question_type === QuestionType.Text) {
              if (question.question_id.includes('IMEI')) {
                state.imeiQuestion = question
                state.imeiQuestionNotFound = false
                state.requiredQuestionsLength++
              } else if (question.question_id.includes('PHONE_NUM')) {
                state.phoneQuestion = question
                state.requiredQuestionsLength++
              } else {
                state.textQuestions.push(question)
                state.requiredQuestionsLength++
              }
            }
          }
        })
        return {
          ...state,
          deviceQuestions: questions,
          loading: false,
        }
      }
    }
    case ActionTypes.FETCH_DEVICE_QUESTIONS_ERROR:
      return {
        ...state,
        loading: false,
        questionsError: true, // turn on the error flag
        questionsErrorResponse: payload,
        error: true,
      }
    case ActionTypes.UPDATE_QUOTE_REQUEST_ANSWER: {
      const index = state.quoteRequestAnswers.findIndex(
        (answer) => answer.question_id === payload.question_id,
      )
      // if there is an entry already update it if not create a new one
      if (index > -1) {
        if (payload.answer_text === '') {
          state.quoteRequestAnswers.splice(index, 1)
        } else {
          state.quoteRequestAnswers[index] = payload
        }
      } else {
        if (payload.answer_text !== '') {
          state.quoteRequestAnswers.push(payload)
        }
      }
      return { ...state, loading: false, error: false }
    }
    case ActionTypes.POST_QUOTE_REFRESH:
      return {
        ...state,
        loading: false,
        quoteError: false,
        imeiValidated: false,
      }
    case ActionTypes.POST_QUOTE_LOAD:
      return {
        ...state,
        loading: true,
        loadNext: false,
      }
    case ActionTypes.POST_QUOTE:
      return {
        ...state,
        loading: false,
        quoteError: false,
        imeiValidated: true,
        quoteResponse: payload,
      }
    case ActionTypes.POST_QUOTE_ERROR:
      return {
        ...state,
        deviceValidationResponse: payload, // TODO do we want to distinguish between the general quote error and imei error?
        imeiValidated: true,
        loading: false,
        quoteError: true,
        quoteErrorResponse: payload,
      }

    case ActionTypes.SAVE_DEVICE_CONDITIONS_RESPONSE_TO_SESSION:
      return {
        ...state,
        deviceConditionsResponse: payload,
        conditionsResponseError: false,
      }
    case ActionTypes.SAVE_DEVICE_CONDITIONS_RESPONSE_TO_SESSION_ERROR:
      return { ...state, conditionsResponseError: true }
    case ActionTypes.FETCH_DEVICE_CONDITIONS_RESPONSE_FROM_SESSION:
      return {
        ...state,
        deviceConditionsResponse: payload,
        conditionsResponseError: false,
      }
    case ActionTypes.FETCH_DEVICE_CONDITIONS_RESPONSE_FROM_SESSION_ERROR:
      return { ...state, conditionsResponseError: true }

    case ActionTypes.SAVE_DEVICE_NUMBER_TO_SESSION:
      return { ...state, deviceNumber: payload, deviceNumberError: false }
    case ActionTypes.SAVE_DEVICE_NUMBER_TO_SESSION_ERROR:
      return { ...state, deviceNumberError: true }
    case ActionTypes.FETCH_DEVICE_NUMBER_FROM_SESSION:
      return { ...state, deviceNumber: payload, deviceNumberError: false }
    case ActionTypes.FETCH_DEVICE_NUMBER_FROM_SESSION_ERROR:
      return { ...state, deviceNumberError: true }

    case ActionTypes.SAVE_PRICE_RESPONSE_TO_SESSION:
      return { ...state, quoteResponse: payload, finalQuoteError: false }
    case ActionTypes.SAVE_PRICE_RESPONSE_TO_SESSION_ERROR:
      return { ...state, finalQuoteError: true }
    case ActionTypes.FETCH_PRICE_RESPONSE_FROM_SESSION:
      return { ...state, quoteResponse: payload, finalQuoteError: false }
    case ActionTypes.FETCH_PRICE_RESPONSE_FROM_SESSION_ERROR:
      return { ...state, finalQuoteError: true }
    default:
      return state
  }
}
