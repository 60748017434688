import { TradeInProvider } from '../../enums/tradein_provider'
import { ActionTypes } from '../constants/action-types'
import { Params } from '../../interfaces/params'
import { Action } from '../interfaces/action'
import { Filters } from '../../interfaces/filters'
import { FilterType } from '../../interfaces/filter_type'
import { axiosInstance } from '../../apis/axiosInstance'
import { handleException } from '../../utils/error-handler'

export const fetchingManufacturers = () => {
  return {
    type: ActionTypes.FETCHING_MANUFACTURERS,
  }
}
// Fetch manufacturers needs filters and tradein provider, ex, Assurant.
// The filter needs to pass the CATEGORY and it needs to be encoded.
//
// Plain filter example:
// {"filters":[{"name":"CATEGORY", "value":"iPhone"}]}
//
// Encoded filter from above example:
// %7B%22filters%22%3A%5B%7B%22name%22%3A%22CATEGORY%22%2C%20%22value%22%3A%22iPhone%22%7D%5D%7D
//
// Local backend tradein service:
// http://localhost:8080/tradein_services/v1/product_filters?tradein_provider=HYLA&filters=%7B%22filters%22%3A%5B%7B%22name%22%3A%22CATEGORY%22%2C%20%22value%22%3A%22iPhone%22%7D%5D%7D
//
// Tap backend tradein service:
// https://tradeinservicesv1.dev.target.com/tradein_services/v1/product_filters?tradein_provider=HYLA&filters=%7B%22filters%22%3A%5B%7B%22name%22%3A%22CATEGORY%22%2C%20%22value%22%3A%22iPhone%22%7D%5D%7D
export const fetchManufacturers = (
  baseURL: string,
  filters: Filters,
  limit: number,
) => {
  return async (dispatch: any) => {
    try {
      const filterEncoded = encodeURIComponent(JSON.stringify(filters))

      const params: Params = {
        tradein_provider: TradeInProvider.Assurant,
        per_page: limit,
      }
      // Call the backend tradein services to get the manufactures
      const response = await axiosInstance.get(
        `${baseURL}/product_filters?filters=${filterEncoded}`,
        { params },
      )
      let action: Action = {
        type: ActionTypes.FETCH_MANUFACTURERS,
        payload: response.data.available_filters.filter(
          (available_filter) =>
            available_filter.filter_name === FilterType.MANUFACTURER,
        )[0].data,
      }
      dispatch(action)
    } catch (error: any) {
      handleException(error.response)
      let errorAction: Action = {
        type: ActionTypes.FETCH_MANUFACTURERS_ERROR,
      }
      dispatch(errorAction)
    }
  }
}
