import styled, { createGlobalStyle } from 'styled-components'
import ndsTokens from 'nicollet-tokens'
import { Input, InputPhoneNumber, Row, Select } from '@nicollet/react'

export const ButtonContainer = styled.div`
  margin-bottom: 40px;
  @media (min-width: ${ndsTokens.breakpoints.lg}) {
    margin-right: 35px;
    margin-top: 50px;
  }
`

export const RefinedInputPhoneNumber = styled(InputPhoneNumber)`
  margin-bottom: 35px;
  @media (min-width: ${ndsTokens.breakpoints.lg}) {
    margin-right: 35px;
  }
  label {
    color: ${ndsTokens.colors.palette.gray.dark};
  }
  .searchInput {
    border: 1px solid ${ndsTokens.colors.palette.gray.medium};
  }
`

export const ModalStyle = createGlobalStyle`
  .ReactModal__Content {
    padding-bottom: 25px;
   }
`

export const RefinedInput = styled(Input)`
  margin-bottom: 35px;
  @media (min-width: ${ndsTokens.breakpoints.lg}) {
    margin-right: 35px;
  }
  label {
    color: ${ndsTokens.colors.palette.gray.dark};
  }
  .searchInput {
    border: 1px solid ${ndsTokens.colors.palette.gray.medium};
  }
`

export const RefinedSelect = styled(Select)`
  margin-bottom: 35px;
  @media (min-width: ${ndsTokens.breakpoints.lg}) {
    margin-right: 35px;
  }
  label {
    color: ${ndsTokens.colors.palette.gray.dark};
  }
  .select {
    border: 1px solid ${ndsTokens.colors.palette.gray.medium};
  }
`

export const PaddedRow = styled(Row)`
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding-bottom: 20px;
  }
`
