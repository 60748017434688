import { TradeInProvider } from '../../enums/tradein_provider'
import { ActionTypes } from '../constants/action-types'
import { Params } from '../../interfaces/params'
import { Action } from '../interfaces/action'
import { Filters } from '../../interfaces/filters'
import { FilterType } from '../../interfaces/filter_type'
import { FilterValue } from '../../interfaces/apiModels/response/filter_value'
import { Storagekey } from '../constants/storage-keys'
import { sessionStorage } from '@web/storage'
import { axiosInstance } from '../../apis/axiosInstance'
import { handleException } from '../../utils/error-handler'

export const fetchingCapacities = () => {
  return {
    type: ActionTypes.FETCHING_CAPACITIES,
  }
}
// Fetch device models needs filters and tradein provider, ex, Assurant.
// The filter needs to pass the CATEGORY and MANUFACTURER and it needs to be encoded.
//
// Plain filter example:
// {"filters":[{"name":"CATEGORY", "value":"iPhone"}, {"name":"MANUFACTURER", "value":"Apple"}, {"name":"PRODUCT_FAMILY", "value":"iphone11"}]}
export const fetchCapacitites = (
  baseURL: string,
  filters: Filters,
  limit: number,
) => {
  return async (dispatch: any) => {
    try {
      const filterEncoded = encodeURIComponent(JSON.stringify(filters))

      const params: Params = {
        tradein_provider: TradeInProvider.Assurant,
        per_page: limit,
      }
      // Call the backend tradein services to get the model variants
      const response = await axiosInstance.get(
        `${baseURL}/product_filters?filters=${filterEncoded}`,
        { params: params },
      )
      let payload: FilterValue[] = response.data.available_filters.filter(
        (available_filter) =>
          available_filter.filter_name === FilterType.CAPACITY,
      )[0].data

      dispatch(saveCapacititesToSessionStorage(payload))

      let action: Action = {
        type: ActionTypes.FETCH_CAPACITIES,
        payload: payload,
      }
      dispatch(action)
    } catch (error: any) {
      handleException(error.response)
      let errorAction: Action = {
        type: ActionTypes.FETCH_CAPACITIES_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const saveCapacititesToSessionStorage = (capacities: FilterValue[]) => {
  return async (dispatch: any) => {
    try {
      await sessionStorage.set(Storagekey.CAPACITIES, capacities)
      let action: Action = {
        type: ActionTypes.SAVE_CAPACITIES_TO_SESSION,
        payload: capacities,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.SAVE_CAPACITIES_TO_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const fetchCapacitiesFromSessionStorage = () => {
  return async (dispatch: any) => {
    try {
      const value = await sessionStorage.get(Storagekey.CAPACITIES)
      let action: Action = {
        type: ActionTypes.FETCH_CAPACITIES_FROM_SESSION,
        payload: value,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.FETCH_CAPACITIES_FROM_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}
