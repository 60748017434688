import React from 'react'
import { Link as NextLink } from 'react-router-dom'
import { IconNavigationArrowLeftFill } from 'nicollet-icons-react'
import { TitleContainer, PreviousStepContainer, RefinedSpan } from './styles'
import { BreadcrumbProperties } from '../breadCrumbNavigation/breadcrumb-properties'

export const MobileNavigation: React.FC<BreadcrumbProperties> = ({
  breadcrumbFixture,
  pathname,
}) => {
  const pathnames: string[] = (pathname || '')?.split('/').filter((x) => x)
  const displayComponent: string = pathnames[pathnames.length - 1]
  let breadcrumbTitle = ''
  let routeTo = ''
  let xIndex: number = pathnames.indexOf('X')

  breadcrumbFixture.forEach((element) => {
    if (element.component === displayComponent) {
      breadcrumbTitle = element.title
      if (pathnames.length > 1) {
        if (xIndex > 0) {
          routeTo = `/${pathnames
            .slice(0, pathnames.indexOf(element.component) - 3)
            .join('/')}`
        } else {
          routeTo = `/${pathnames
            .slice(0, pathnames.indexOf(element.component) - 1)
            .join('/')}`
        }
      }
    }
  })

  return (
    <>
      {pathnames.length > 1 && (
        <NextLink to={routeTo}>
          <PreviousStepContainer>
            <IconNavigationArrowLeftFill
              fill="gray"
              height="14px"
              width="15px"
            />
            <RefinedSpan>Previous step</RefinedSpan>
          </PreviousStepContainer>
        </NextLink>
      )}
      <TitleContainer> {breadcrumbTitle} </TitleContainer>
    </>
  )
}
