import {
  ButtonPrimary,
  ButtonSecondary,
  Checkbox,
  Col,
  Grid,
  Link,
  ModalFloatOneButton,
  Row,
} from '@nicollet/react'
import {
  HeadingContainer,
  HeadingPropsDiv,
  StyledGridContainer,
  SubHeadingContainer,
} from '../../../common/styles/global-styles'
import {
  AcceptTcRow,
  LowerLeftButton,
  LowerRightButton,
  ModalStyle,
  PaddedText,
  PriceCol,
  PriceRow,
} from './styles'
import { useNavigate } from 'react-router-dom'
import { Paths } from '../../enums/paths'
import { LabeledImage } from '../../components/labeledImage'
import { InfoPane } from '../../components/infoPane'
import { Helmet } from 'react-helmet'
import { useCallback, useEffect, useState } from 'react'
import { PopoverInModalStyles } from '@nicollet/react/BaseModalFloat/styles'
import { useAnalytics } from '@praxis/component-analytics'
import { TermsAndConditions } from '../../components/termsAndConditions'
import { TradeInProvider } from '../../enums/tradein_provider'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProductFromSessionStorage } from '../../store/actions/productSelectedStorageActions'
import { Product } from '../../interfaces/apiModels/response/products/product'
import { RootState } from '../../store/reducers'
import {
  fetchDeviceConditionsResponseFromSessionStorage,
  fetchDeviceNumberFromSessionStorage,
  fetchFinalQuoteFromSessionStorage,
} from '../../store/actions/deviceConditionsActions'
import { DeviceConditionsState } from '../../store/interfaces/device_conditions_state'
import { OrderRequest } from '../../interfaces/apiModels/response/order/order_request'
import { CustomerState } from '../../store/interfaces/customer_state'
import { postOrder } from '../../store/actions/orderActions'
import { fetchCustomerFromSessionStorage } from '../../store/actions/customerActions'
import { BaseURLState } from '../../store/interfaces/base_url_state'
import { fetchBaseURLFromSessionStorage } from '../../store/actions/baseURLStorageActions'
import { ErrorPageNotFound } from '../../components/pageNotFound'

export const TradeInReview = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()

  const { trackClick, trackEvent } = useAnalytics()

  const onSubmit = (path: Paths) => {
    trackPrice(quoteResponse.amount.toString())
    let orderRequest: OrderRequest = {
      tradein_provider: TradeInProvider.Assurant,
      tradein_provider_product_id: selectedProduct.tradein_provider_product_id,
      quote_id: quoteResponse.quote_id,
      customer: customerState.customer,
    }
    trackClick('Accepted_TC')
    dispatch(postOrder(baseURL, orderRequest))
    navigate(path)
  }

  let baseURLDetails: BaseURLState = useSelector((state: RootState) => {
    return state.baseURL
  })
  let { baseURL, baseURLExist } = baseURLDetails

  useEffect(() => {
    dispatch(fetchBaseURLFromSessionStorage())
  }, [])

  useEffect(() => {
    if (baseURLExist) {
      dispatch(fetchProductFromSessionStorage())
      dispatch(fetchDeviceNumberFromSessionStorage())
      dispatch(fetchDeviceConditionsResponseFromSessionStorage())
      dispatch(fetchFinalQuoteFromSessionStorage())
      dispatch(fetchCustomerFromSessionStorage())
    }
  }, [baseURLExist])

  const [screenWidth, setScreenWidth]: [number, (checked: number) => void] =
    useState(window.screen.width)

  useEffect(() => {
    setScreenWidth(window.screen.width)
  }, [screenWidth])

  const isMobile = () => {
    return screenWidth < 480
  }

  let selectedProduct: Product = useSelector((state: RootState) => {
    return state.productSelection.selectedProduct
  })

  let customerState: CustomerState = useSelector((state: RootState) => {
    return state.customer
  })

  let deviceConditions: DeviceConditionsState = useSelector(
    (state: RootState) => {
      return state.deviceConditions
    },
  )

  let { deviceNumber, quoteResponse, deviceConditionsResponse } =
    deviceConditions

  const trackPrice = (price) => {
    if (price) {
      trackEvent({
        event: {
          name: 'trade_amount',
          type: 'number',
        },
        user: {
          customDimension1: price,
        },
      })
    }
  }

  const [acceptedTerms, setAcceptedTerms] = useState(false)

  const [isTcOpen, setIsTcOpen] = useState(false)
  const requestTcOpenCallback = useCallback(() => {
    trackClick('Opened_TC_Modal')
    setIsTcOpen(true)
  }, [])
  const requestTcCloseCallback = useCallback(() => {
    setIsTcOpen(false)
  }, [])

  const defaultProps = {
    buttonText: 'Accept Terms',
    'data-test': 'custom',
    headingText: 'Trade-in Program Terms & Conditions',
    hasPrimaryButton: true,
    isOpen: isTcOpen,
    onRequestClose: requestTcCloseCallback,
    iconButtonCloseOnClick: requestTcCloseCallback,
    oneButtonOnClick: () => {
      setAcceptedTerms(true)
      requestTcCloseCallback()
    },
  }

  const ContinueButton = () => {
    return (
      <LowerRightButton xs={12} lg={6}>
        <ButtonPrimary
          isFullWidth
          disabled={!acceptedTerms}
          onClick={() => onSubmit(Paths.TradeComplete)}
        >
          Complete trade submission
        </ButtonPrimary>
      </LowerRightButton>
    )
  }

  return (
    <Grid>
      <Helmet defaultTitle="Review Trade-in" />
      {baseURLExist ? (
        <StyledGridContainer>
          <HeadingContainer role="heading" aria-level="2">
            Review Trade-in
          </HeadingContainer>
          <SubHeadingContainer>
            <label>Please verify that this information is correct</label>
          </SubHeadingContainer>
          <Row style={{ marginTop: '45px' }}>
            <Col lg={3} xs={12}>
              <PaddedText>You're trading in:</PaddedText>
              <LabeledImage
                altText={selectedProduct.product_title}
                imageUrl={selectedProduct.image_url}
                label={selectedProduct.product_title}
                imageWidth={'150'}
                page={'ReviewPage'}
              />
            </Col>
            <Col lg={9} xs={12}>
              <InfoPane
                deviceName={selectedProduct.product_title}
                imei={deviceNumber}
                quoteDetails={deviceConditionsResponse}
              />
            </Col>
          </Row>
          <PriceRow end={'xs'}>
            <Col lg={6} xs={9}>
              <HeadingPropsDiv role="heading" aria-level="2">
                Estimated Device trade-in Value:
              </HeadingPropsDiv>
            </Col>
            <PriceCol lg={3} xs={3}>
              ${quoteResponse.amount.toFixed(2)}
            </PriceCol>
          </PriceRow>
          <AcceptTcRow>
            <Col>
              <Checkbox
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
                ariaLabel="I accept these Terms and Conditions"
              />
            </Col>
            <Col xs={9}>
              <Link
                onClick={requestTcOpenCallback}
                aria-label="Terms and Conditions, opens in a PDF modal "
              >
                I accept these Terms and Conditions
              </Link>
            </Col>
          </AcceptTcRow>
          <ModalStyle />
          <ModalFloatOneButton {...defaultProps}>
            <Row>
              <TermsAndConditions provider={TradeInProvider.Assurant} />
            </Row>
          </ModalFloatOneButton>
          <PopoverInModalStyles />
          <Row>
            {isMobile() && <ContinueButton />}
            <LowerLeftButton xs={12} lg={6}>
              <ButtonSecondary isFullWidth href={Paths.Home}>
                Cancel
              </ButtonSecondary>
            </LowerLeftButton>
            {!isMobile() && <ContinueButton />}
          </Row>
        </StyledGridContainer>
      ) : (
        <ErrorPageNotFound />
      )}
    </Grid>
    //  Test Zid Commit
  )
}
