import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { CategoryState } from '../interfaces/category_state'

const initialState: CategoryState = {
  categories: [],
  categoriesEmpty: false,
  count: 0,
  loading: false,
  categoryError: false,
}
export const categoryReducer = (
  state: CategoryState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionTypes.FETCHING_CATEGORIES:
      return { ...state, loading: true, categories: [] }
    case ActionTypes.FETCH_CATEGORIES:
      return {
        ...state,
        count: payload.length,
        categories: payload,
        loading: false,
        categoriesEmpty: payload.length === 0 ? true : false,
      }
    case ActionTypes.FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        categoryError: true,
      }
    default:
      return state
  }
}
