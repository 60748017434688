import { ActionTypes } from '../constants/action-types'
import { TradeInProvider } from '../../enums/tradein_provider'
import { Filters } from '../../interfaces/filters'
import { Params } from '../../interfaces/params'
import { Action } from '../interfaces/action'
import { axiosInstance } from '../../apis/axiosInstance'
import { handleException } from '../../utils/error-handler'
import { Storagekey } from '../constants/storage-keys'
import { sessionStorage } from '@web/storage'

export const fetchingModels = () => {
  return {
    type: ActionTypes.FETCHING_MODELS,
  }
}

export const fetchModels = (
  baseURL: string,
  page: number,
  perPage: number,
  filters: Filters,
) => {
  return async (dispatch: any) => {
    try {
      const filterEncoded = encodeURIComponent(JSON.stringify(filters))
      const params: Params = {
        page: page,
        per_page: perPage,
        tradein_provider: TradeInProvider.Assurant,
      }
      const response = await axiosInstance.get(
        `${baseURL}/products?filters=${filterEncoded}`,
        { params: params },
      )
      let action: Action = {
        type: ActionTypes.FETCH_MODELS,
        payload: response.data,
      }
      dispatch(action)
    } catch (error: any) {
      handleException(error.response)
      let errorAction: Action = { type: ActionTypes.FETCH_MODELS_ERROR }
      dispatch(errorAction)
    }
  }
}

export const saveModelsToSessionStorage = (products: any) => {
  return async (dispatch: any) => {
    try {
      await sessionStorage.set(Storagekey.KEYWORD_SEARCH_RESULTS, products)
      let action: Action = {
        type: ActionTypes.SAVE_SEARCH_RESULTS_TO_SESSION,
        payload: products,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.SAVE_SEARCH_RESULTS_TO_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}

export const fetchModelsFromSessionStorage = () => {
  return async (dispatch: any) => {
    try {
      const value = await sessionStorage.get(Storagekey.KEYWORD_SEARCH_RESULTS)
      let action: Action = {
        type: ActionTypes.FETCH_SEARCH_RESULTS_FROM_SESSION,
        payload: value,
      }
      dispatch(action)
    } catch (error) {
      let errorAction: Action = {
        type: ActionTypes.FETCH_SEARCH_RESULTS_FROM_SESSION_ERROR,
      }
      dispatch(errorAction)
    }
  }
}
