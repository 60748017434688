import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'
import { Grid, Row } from '@nicollet/react'
import { HeadingProps } from '../../../common/interfaces/heading_props'

const borderWidth = '2px'
const borderRadius = '5px'
const borderColor = ndsTokens.colors.palette.gray.light
const headerBackgroundColor = ndsTokens.colors.palette.gray.lightest

export const InfoGrid = styled(Grid)`
  border-style: solid;
  border-color: ${borderColor};
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  border-width: ${borderWidth};
  border-bottom: none;
  width: auto;
`
export const HeaderRow = styled(Row)`
  background-color: ${headerBackgroundColor};
  padding: 20px 25px;
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  border-bottom-style: solid;
  border-bottom-width: ${borderWidth};
  border-bottom-color: ${borderColor};
`
export const BodyRow = styled(Row)`
  padding: 10px 25px;
  border-bottom-style: solid;
  border-bottom-width: ${borderWidth};
  border-bottom-color: ${borderColor};
`
export const DetailRow = styled(BodyRow)`
  display: block;
`
export const RowLabel = styled.div<HeadingProps>`
  font-size: ${ndsTokens.font.size.heading.level4};
  font-family: ${ndsTokens.font.family};
  font-weight: ${ndsTokens.font.weight.bold};
`
export const Bold = styled.span`
  font-weight: ${ndsTokens.font.weight.bold};
`
