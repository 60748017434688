import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'

export const Card = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  :hover {
    border-style: dashed;
    border-color: ${ndsTokens.colors.border.container.active};
    // border-color: rgb(51, 51, 51);
    border-width: 1px;
  }
`

export const CardContainer = styled.div`
  height: 100%;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`
