import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'

export const StyledImageContainer = styled.div`
  margin-bottom: 40px;
  text-align: center;
`

export const StyledImg = styled.img`
  margin-bottom: 20px;
  cursor: pointer;
  height: 120px;
  width: 140px;
  text-align: center;
  display: inline;
`

export const RefinedSpan = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  font-size: ${ndsTokens.font.size.text.large};
  font-weight: 600;
`

export const GenericErrorContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
  align-items: left;
  text-align: left;
  color: ${ndsTokens.colors.palette.red.default};
  font-size: ${ndsTokens.font.size.text.large};
`
