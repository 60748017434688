import { Heading, Spinner } from '@nicollet/react'
import { StyledGridContainer } from '../../../common/styles/global-styles'
import { DisplayDeviceModel } from '../../components/displayDeviceModel'
import { HeadingContainer, NoProductContainer } from './styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { Paths } from '../../enums/paths'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  fetchingModels,
  fetchModels,
  fetchModelsFromSessionStorage,
} from '../../store/actions/modelActions'
import { RootState } from '../../store/reducers'
import { Filters } from '../../interfaces/filters'
import { FilterType } from '../../interfaces/filter_type'
import { ModelState } from '../../store/interfaces/model_state'
import { Helmet } from 'react-helmet'
import { saveProductToSessionStorage } from '../../store/actions/productSelectedStorageActions'
import { Product } from '../../interfaces/apiModels/response/products/product'
import { BaseURLState } from '../../store/interfaces/base_url_state'
import { fetchBaseURLFromSessionStorage } from '../../store/actions/baseURLStorageActions'
import { PER_PAGE_RESULTS } from '../../constants/common-constants'

export const SearchResults = () => {
  let navigate = useNavigate()
  let location = useLocation()
  const dispatch = useDispatch()
  const perPage: number = 12

  const keyword: string | null = new URLSearchParams(location.search).get(
    'value',
  )
  const routeNext = (product: Product) => {
    dispatch(saveProductToSessionStorage(product))
    navigate(
      Paths.DeviceConditions + '?id=' + product.tradein_provider_product_id,
    )
  }

  const getProducts = (page: number) => {
    dispatch(fetchingModels())
    if (keyword && keyword !== '') {
      let filters: Filters = {
        filters: [{ name: FilterType.Keyword, value: keyword }],
      }
      dispatch(fetchModels(baseURL, page, PER_PAGE_RESULTS, filters))
    }
  }
  let baseURLDetails: BaseURLState = useSelector((state: RootState) => {
    return state.baseURL
  })
  let { baseURL, baseURLExist } = baseURLDetails

  useEffect(() => {
    dispatch(fetchBaseURLFromSessionStorage())
  }, [])

  useEffect(() => {
    if (baseURLExist) {
      dispatch(fetchModelsFromSessionStorage())
    }
  }, [baseURLExist])

  let modelState: ModelState = useSelector((state: RootState) => {
    return state.models
  })

  let { count, models, loading, modelError, modelsEmpty } = modelState

  return (
    <div>
      <Helmet defaultTitle={'Search Results for ' + keyword} />
      <>
        <StyledGridContainer>
          {keyword === '' || keyword === null || modelsEmpty || modelError ? (
            <NoProductContainer>
              <Heading size={2}>
                Sorry no Products found... Please try again with a different
                search
              </Heading>
            </NoProductContainer>
          ) : (
            <div>
              <HeadingContainer>
                <Heading size={2}>Results for "{keyword}"</Heading>
              </HeadingContainer>
              {loading && <Spinner size="large" />}
              <DisplayDeviceModel
                onChangeCallBack={getProducts}
                onProductSelect={routeNext}
                perPage={perPage}
                deviceModels={models}
                modelsCount={count}
              />
            </div>
          )}
        </StyledGridContainer>
      </>
    </div>
  )
}
