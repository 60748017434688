import { BreadcrumbNavigation } from '../../components/breadCrumbNavigation'
import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { MobileNavigation } from '../../components/mobileNavigation'
import breadcrumb from './breadcrumb.json'
import { BreadCrumbModel } from './breadcrumb-model'
import { BreadCrumbContainer, MobileNavigationContainer } from './styles'

export const BrowseCategoryLayout = () => {
  let location = useLocation()
  const breadCrumbList: BreadCrumbModel[] = breadcrumb
  const [screenWidth, setScreenWidth]: [number, (checked: number) => void] =
    useState(window.screen.width)

  useEffect(() => {
    setScreenWidth(window.screen.width)
  }, [screenWidth])

  const isMobile = () => {
    if (screenWidth < 480) {
      return true
    }
    return false
  }

  return (
    <>
      {!isMobile() && (
        <BreadCrumbContainer>
          <BreadcrumbNavigation
            breadcrumbFixture={breadCrumbList}
            pathname={location?.pathname}
          />
        </BreadCrumbContainer>
      )}
      {isMobile() && (
        <MobileNavigationContainer>
          <MobileNavigation
            breadcrumbFixture={breadCrumbList}
            pathname={location?.pathname}
          />
        </MobileNavigationContainer>
      )}
    </>
  )
}
