import styled from 'styled-components'
import ndsTokens from 'nicollet-tokens'

export const PageSelection = styled.div`
  justify-content: left;
  align-items: left;
  padding-left: 15px;
  font-size: ${ndsTokens.font.size.text.large};
  span.h-padding-h-tiny {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
`

export const RefinedSpan = styled.span`
  font-weight: bold;
`

export const LinkRefinedSpan = styled.span`
  color: ${ndsTokens.colors.palette.gray.dark};
`

export const SelectedValueContainer = styled.span`
  .fkzpKq {
    color: ${ndsTokens.colors.palette.gray.medium};
    font-size: 18px;
  }
`
