import styled from 'styled-components'
import { Col, Row } from '@nicollet/react'
import ndsTokens from 'nicollet-tokens'
import { HeadingProps } from '../../../common/interfaces/heading_props'

export const MainList = styled.ol`
  list-style-type: upper-alpha;
  font-weight: bold;
  li {
    padding-top: 20px;
    text-transform: uppercase;
  }
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding-left: 20px;
  }
`

export const BulletedList = styled.ol`
  list-style-type: circle;
  font-weight: normal;

  li {
    padding-top: 10px;
    text-transform: none;
  }
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding-left: 15px;
  }
`

export const NumberedList = styled.ol`
  list-style-type: decimal;
  font-weight: normal;

  li {
    padding-top: 10px;
    text-transform: none;
  }
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding-left: 15px;
  }
`

export const LowerAlphaList = styled.ol`
  list-style-type: lower-alpha;
  font-weight: normal;

  li {
    padding-top: 10px;
    text-transform: none;
  }
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding-left: 20px;
  }
`

export const UnmarkedList = styled.ol`
  list-style-type: none;
  font-weight: normal;

  li {
    padding-top: 10px;
    text-transform: none;
  }
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding-left: 15px;
  }
`

export const Bold = styled.span`
  display: contents;
  font-weight: ${ndsTokens.font.weight.bold};
`

export const PrintOnlyHeader = styled.h2<HeadingProps>`
  display: none;
  @media print {
    display: block;
  }
`

export const LogoRow = styled(Row)`
  img {
    height: 75px;
  }
  @media print {
    display: none;
  }
`

export const LowerLeftButton = styled(Col)`
  padding-right: 10px;
  a {
    text-decoration: none;
  }
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding: 5px;
  }
`

export const LowerRightButton = styled(Col)`
  padding-left: 10px;
  @media (max-width: ${ndsTokens.breakpoints.sm}) {
    padding: 5px;
  }
`

export const HiddenIFrame = styled.iframe`
  height: 0;
  width: 0;
  position: absolute;
`
