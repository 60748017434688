import { TandCParams } from './tcParams'
import { Grid, Row } from '@nicollet/react'
import {
  Bold,
  BulletedList,
  LogoRow,
  LowerAlphaList,
  MainList,
  NumberedList,
  PrintOnlyHeader,
  UnmarkedList,
} from './styles'
import { TradeInProvider } from '../../enums/tradein_provider'

export const ConditionsText: React.FC<TandCParams> = ({ provider }) => {
  let conditions
  switch (provider) {
    case TradeInProvider.Assurant:
    default:
      conditions = AssurantTermsAndConditions
  }
  return conditions
}

// The in-line styles were necessary for printing, styled-components styles wouldn't render
const AssurantTermsAndConditions = (
  <div id={'contract'}>
    <Grid>
      <LogoRow>
        <img src="./graphics/Assurant_Logo.png" alt={'Assurant Company Logo'} />
      </LogoRow>
      <Row>
        <PrintOnlyHeader>Trade-in Program Terms & Conditions</PrintOnlyHeader>
      </Row>
      <Row>
        <label>
          This Trade-in Program is a service provided to You by Assurant Inc. (
          <Bold style={{ fontWeight: 'bold' }}>“Assurant”</Bold>), as part of a
          contracted service that Assurant provides to Target Corporation.
          Target Corporation may provide customer service for the Trade-in
          Program, but You understand the Trade-in Program and these Terms and
          Conditions are between you and Assurant only.
        </label>
      </Row>
      <Row>
        <MainList style={{ listStyleType: 'upper-alpha' }}>
          <li>
            Definitions:
            <BulletedList style={{ listStyleType: 'circle' }}>
              <li>
                <Bold style={{ fontWeight: 'bold' }}>"Address"</Bold> means a
                physical street address in the United States (no P.O. boxes or
                foreign addresses accepted) that You may provide during the
                transaction relating to Your Device under the Trade-in Program.
              </li>
              <li>
                <Bold style={{ fontWeight: 'bold' }}>"Email Address"</Bold>{' '}
                means an email address that You submit on the Website for
                payment relating to Your Device under the Trade-in Program.
              </li>
              <li>
                <Bold style={{ fontWeight: 'bold' }}>"Customer"</Bold> or{' '}
                <Bold style={{ fontWeight: 'bold' }}>"You"</Bold> means you, the
                Device owner, or a person having legal authority to make
                decisions about the Device, and who has accepted these Terms and
                Conditions.
              </li>
              <li>
                <Bold style={{ fontWeight: 'bold' }}>
                  "Initial trade-in Quote"
                </Bold>{' '}
                means the initial Device trade-in quote that will be provided to
                You upon your selection of the model and your representation of
                its condition.
              </li>
              <li>
                <Bold style={{ fontWeight: 'bold' }}>
                  “Final trade-in Quote”
                </Bold>{' '}
                means the final trade-in value that You may receive for Your
                Device, assessed by Assurant after physical receipt and
                inspection of Your Device (which may be lower than the Initial
                trade-in Quote).
              </li>
              <li>
                <Bold style={{ fontWeight: 'bold' }}>“Device”</Bold> means Your
                Device that qualifies for the Trade-in Program.
              </li>
              <li>
                <Bold style={{ fontWeight: 'bold' }}>“Trade-in Credit”</Bold>{' '}
                means the method by which You will receive the Device Final
                trade-in Quote if your Device qualifies for it, through Physical
                or Digital Target GiftCard, or any other credit or combination
                of the foregoing, as specified in Section C.6 below.
              </li>
              <li>
                <Bold style={{ fontWeight: 'bold' }}>“Trade-in Period”</Bold>{' '}
                means 45 calendar days from the date You accept the Initial
                trade-in Quote through the Website.
              </li>
              <li>
                <Bold style={{ fontWeight: 'bold' }}>“Trade-in Program”</Bold>{' '}
                means the service provided to You by Assurant whereby You may
                exchange your Device in return for a trade-in Credit, subject to
                these Terms and Conditions.
              </li>
            </BulletedList>
          </li>
          <li>
            You confirm that:
            <BulletedList style={{ listStyleType: 'circle' }}>
              <li>
                You are at least 18 years old and of legal capacity to
                participate in this Trade-in Program.
              </li>
              <li>
                You are the only owner of Your Device and no other third party
                holds any rights of any kind in Your Device (including
                installment or leasing programs).
              </li>
              <li>
                All information You provided as part of this Trade-in Program
                (personal information and Device-related information, including
                Device type and condition) is true and accurate.
              </li>
            </BulletedList>
          </li>
          <li>
            You acknowledge, warrant, and agree that:
            <NumberedList style={{ listStyleType: 'decimal' }}>
              <li>
                The Initial trade-in Quote is an estimate only, and that the
                trade-in Credit You receive, if any, will be based only on the
                Final trade-in Quote determined by Assurant. For example, if
                after inspection, Your Device is not in the condition
                represented through your initial evaluation, or Your Device is
                not one of the Devices accepted by the Trade-in Program, the
                Final trade-in Quote may be minimal or zero, depending on the
                trade-in transaction type.
              </li>
              <li>
                The Initial trade-in Quote expires at the end of the trade-in
                Period. If Your Device is not shipped to and received by
                Assurant within the trade-in Period, only the Final trade-in
                Quote will be effective.
              </li>
              <li>
                Target Corporation and Assurant are not responsible for data
                loss, data recovery, or any related damages under this Trade-in
                Program.{' '}
                <Bold style={{ fontWeight: 'bold' }}>
                  It is Your responsibility to remove all personal data from
                  Your Device before shipping it to Assurant. In addition, you
                  must do the following:
                </Bold>
                <LowerAlphaList style={{ listStyleType: 'lower-alpha' }}>
                  <li>
                    Factory-reset Your Device, unlock Your Device (including
                    removing Your Device from any services that prevent it from
                    being activated), and remove all SIM and memory cards from
                    Your Device;
                  </li>
                  <li>
                    Disconnect Your Device from your existing wireless service
                    plan; and
                  </li>
                  <li>
                    Ship Your Device to Assurant using the shipping guidelines
                    provided. Target Corporation and Assurant will not be liable
                    for any loss or damage to Your Device while in transit.
                  </li>
                </LowerAlphaList>
              </li>
              <li>
                If Assurant cannot process or make future use of Your Device by
                accessing the Device or confirming Device data removal,{' '}
                <Bold style={{ fontWeight: 'bold' }}>
                  You may not receive trade-in Credit for the Device, and You
                  waive all rights and claims with respect to such Device and
                  any data stored on the Device.
                </Bold>
              </li>
              <li>
                Once shipped to Assurant, Your Devices and any accessories will{' '}
                <Bold style={{ fontWeight: 'bold' }}>
                  not be returned to You{' '}
                </Bold>{' '}
                and{' '}
                <Bold style={{ fontWeight: 'bold' }}>
                  any personal data stored on the Device will be destroyed.
                </Bold>
              </li>
              <li>
                Depending on the transaction type and method, You may receive
                the trade-in Credit (if any) in the following form:
                <LowerAlphaList style={{ listStyleType: 'lower-alpha' }}>
                  <li>Physical Target GiftCard;</li>
                  <li>Digital Target GiftCard;</li>
                  <li>Any combination of the above methods;</li>
                </LowerAlphaList>
              </li>
            </NumberedList>
          </li>
          <li>
            Disclaimers & Waivers:{' '}
            <NumberedList style={{ listStyleType: 'decimal' }}>
              <li>
                You acknowledge that Assurant is solely responsible for
                execution of the Trade-in Program. You waive all rights and
                claims against Target Corporation and its respective directors,
                employees, and agents for any loss or damages (direct or
                consequential) of every kind, whether known or unknown, arising
                out of, or relating to, Your use of the Trade-in Program.
              </li>
              <li>
                Assurant and Target Corporation are not responsible for any
                damage, loss, or costs incurred by You in relation to this
                Trade-in Program, resulting from any of the following:
                <LowerAlphaList style={{ listStyleType: 'lower-alpha' }}>
                  <li>
                    Your act, omission, or misrepresentation in violation of the
                    law or these Terms and Conditions. You additionally agree to
                    indemnify Target Corporation and Assurant from and against
                    any claims brought against either of them arising from Your
                    breach of applicable law or these Terms and Conditions.
                  </li>
                  <li>
                    Disclosure of Your personal information to law enforcement
                    agencies if Your Device is found to be stolen or if required
                    by law.
                  </li>
                </LowerAlphaList>
              </li>
              <li>
                You additionally agree that Assurant’s total liability to You
                will not exceed the Final trade-in Quote.
              </li>
              <li>
                Assurant may change these Terms and Conditions to, for example,
                reflect changes to the law or changes to the Trade-in Program.
                Assurant will post notice of changes to these Terms and
                Conditions on this page.
              </li>
            </NumberedList>
          </li>
          <li>
            Governing law & dispute resolution:
            <UnmarkedList style={{ listStyleType: 'none' }}>
              <li>
                THESE TERMS AND CONDITIONS WILL BE GOVERNED IN ALL RESPECTS BY
                THE LAWS OF THE UNITED STATES OF AMERICA AND THE STATE OF TEXAS,
                AND THE SELLER WAIVES ANY RIGHT TO A TRIAL BY JURY RELATING TO
                ANY DISPUTE UNDER THESE TERMS AND CONDITIONS.
              </li>
              <li>
                The Seller agrees that any litigation relating to these Terms
                and Conditions or the Program shall be subject to the exclusive
                jurisdiction of the state or federal courts located in Dallas
                County in the State of Texas. All notices provided by Assurant
                to the Seller under the Program will be provided via email to
                the email address provided by the Seller when registering for
                the Program. The failure of Assurant to require performance by
                the Seller of any provision hereof will not affect Assurant’s
                right to require such performance at any time thereafter; nor
                will the waiver by Assurant of a breach by Seller of these Terms
                and Conditions be taken or held to be a waiver of any other
                breach. If any provision of these Terms and Conditions is held
                to be invalid by any law, rule, order or regulation of any
                government or by the final determination of any state or federal
                court, such invalidity shall not affect the enforceability of
                any other provision of these Terms and Conditions.
              </li>
            </UnmarkedList>
          </li>
          <li>
            Miscellaneous:
            <NumberedList style={{ listStyleType: 'decimal' }}>
              <li>
                Force Majeure. Neither Assurant nor Target Corporation will be
                liable for failure or delay in performance to the extent caused
                by circumstances beyond its reasonable control.
              </li>
              <li>
                No Agency. These Terms and Conditions do not create any agency,
                partnership, or joint venture between the parties.
              </li>
              <li>
                Entire Agreement. To the extent permitted by applicable law,
                these Terms and Conditions state all terms agreed by the parties
                and supersede all other agreements by the parties relating to
                its subject matter.
              </li>
              <li>
                Severability. If any term (or part of a term) of these Terms and
                Conditions is invalid, illegal, or unenforceable, the rest of
                these Terms and Conditions will remain in effect.
              </li>
            </NumberedList>
          </li>
        </MainList>
      </Row>
    </Grid>
  </div>
)
