import { ActionTypes } from '../constants/action-types'
import { Action } from '../interfaces/action'
import { OrderState } from '../interfaces/order_state'

const initialState: OrderState = {
  orderId: '',
  error: false,
  orderErrorResponse: {
    code: '',
    message: '',
  },
  loading: false,
  orderSubmitted: false,
}
export const orderReducer = (
  state: OrderState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionTypes.POSTING_ORDER:
      return { ...state, loading: true, orderSubmitted: false }
    case ActionTypes.POST_ORDER_REFRESH:
      return { ...state, loading: false }
    case ActionTypes.POST_ORDER:
      return {
        ...state,
        count: payload.order_id,
        loading: false,
        error: false,
        orderSubmitted: true,
      }
    case ActionTypes.POST_ORDER_ERROR:
      return {
        ...state,
        order_id: '',
        loading: false,
        error: true,
        orderErrorResponse: payload,
      }
    case ActionTypes.SAVE_ORDER_ID_TO_SESSION:
      return { ...state, orderId: payload, error: false }
    case ActionTypes.SAVE_ORDER_ID_TO_SESSION_ERROR:
      return { ...state, error: true }
    case ActionTypes.FETCH_ORDER_ID_FROM_SESSION:
      return { ...state, orderId: payload, error: false }
    case ActionTypes.FETCH_ORDER_ID_FROM_SESSION_ERROR:
      return { ...state, error: true }
    default:
      return state
  }
}
